import React, { useState, useEffect } from 'react';
import { Container, Button, Accordion, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import translations from '../../translate.js';
import '../../styles/filters.scss';
import { useTheme } from '../../ThemeContext.js';
import pluss from '../../img/Add.svg';
import backIcon from '../../img/Back.svg';
import editIcon from '../../img/Edit.svg'; 
import { useNavigate } from 'react-router-dom';

function FiltersPage() {
  const [filters, setFilters] = useState([]);
  const [inactiveFilters, setInactiveFilters] = useState([]);
  const [role, setRole] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { theme } = useTheme();
  const navigate = useNavigate();

  const language = localStorage.getItem('language') || 'lv';
  const text = translations[language];

  // Fetch filters and update lists
  const fetchFilters = async () => {
    try {
      const response = await axios.get('/filters/get/filters');
      console.log("Filters API Response:", response.data); // Debug API data

      const formattedFilters = Object.keys(response.data).map(filterId => ({
        filterId,
        name: response.data[filterId][0].filterName || text.unnamedFilter,
        active: response.data[filterId][0].active,
      }));

      const activeFilters = formattedFilters.filter(filter => filter.active === 1);
      const inactiveFilters = formattedFilters.filter(filter => filter.active === 0);

      setFilters(activeFilters);
      setInactiveFilters(inactiveFilters);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
    const getUser = async () => {
      try {
        const response = await axios.get('/api/get/user', { withCredentials: true });
        setRole(response.data.role);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    getUser();
  }, []);

  const getFilterLimit = () => {
    if (role === 'admin') return 20;
    if (role === 'advanced') return 10;
    if (role === 'basic') return 5;
    return 0;
  };

  // Atver esoša filtra rediģēšanas lapu
  const handleFilterClick = (filterId) => {
    navigate(`/filters/update/${filterId}`);
  };

  // Atver jauna filtra veidošanas lapu
  const handleAddFilterClick = () => {
    navigate('/filters/new');
  };
  
  return (
    <div data-theme={theme}>
      <Container fluid id='filter-page'>

        {/* Back Button and Title */}
        <div className="title-container">
          <img
            src={backIcon}
            alt="Back"
            className="back-icon"
            onClick={() => navigate('/main')}
          />
          <h2 className="title">{text.filters}</h2>
        </div>

        {/* Access Restriction Alert
        {(role !== 'basic' && role !== 'admin' && role !== 'advanced') && (
          <Alert id='restricted-alert'>
            <Alert.Heading>{text.accessRestricted}</Alert.Heading>
            <hr />
            <p>{text.upgradePlan}</p>
          </Alert>
        )} */}

        {/* Active Filters Section */}
        <div className="filters-section">
            <div className="section-title">{text.activeFilters} ({filters.length}/{getFilterLimit()})</div>
            <Accordion alwaysOpen>
              {filters.length > 0 ? (
                filters.map((filter) => (
                  <div key={filter.filterId} className="filter-item-container">
                    <div className="filter-item" onClick={() => handleFilterClick(filter.filterId)}>
                    <span className="filter-name">{filter.name}</span>
                      <div className="edit-container">
                      <img 
                        src={editIcon} 
                        alt="Edit" 
                        className="edit-icon"
                        onClick={() => handleFilterClick(filter.filterId)} 
                      />
                    </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-placeholder">{text.noActiveFilters}</div>
              )}
            </Accordion>
          </div>

          <div className="btn-new-container">
            <Button onClick={handleAddFilterClick} className="add-filter-btn">
              <img src={pluss} alt="Add Filter" />
            </Button>
          </div>


        {/* Inactive Filters Section */}
        <div className="filters-section">
          <div className="section-title">{text.inactiveFilters} ({inactiveFilters.length})</div>
          <Accordion alwaysOpen>
            {inactiveFilters.length > 0 ? (
              inactiveFilters.map(filter => (
                <div key={filter.filterId} className="filter-item-container">
                  <div className="filter-item inactive" onClick={() => handleFilterClick(filter.filterId)}>
                     <span className="filter-name">{filter.name}</span>
                    <div className="edit-container">
                      <img 
                        src={editIcon} 
                        alt="Edit" 
                        className="edit-icon"
                        onClick={() => handleFilterClick(filter.filterId)} 
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-placeholder">{text.noInactiveFilters}</div>
            )}
          </Accordion>
        </div>

        {/* Modal for Filter Limit Reached */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{text.activeFilterLimitReached}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{text.upgradeModalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>{text.cancel}</Button>
            <Button variant="primary" disabled>{text.upgrade}</Button>
          </Modal.Footer>
        </Modal>

      </Container>
    </div>
  );
}

export default FiltersPage;
