const translations = {
    lv: {
        filterLimitTitle: "Nevar aktivizēt filtru",
        filterLimitMessage: "Šī filtra aktivizēšana pārsniedz jūsu aktīvo filtru limitu. Izslēdz esošu filtru vai uzlabo savu plānu, lai to aktivizētu.",
        ok: "OK",
        confirmDeleteTitle: "Dzēst",
        confirmDeleteMessage: "Šī darbība <span class='highlight-text'>neatgriezeniski izdzēsīs</span> jūsu filtru un <span class='highlight-text'>to nevar atsaukt</span>.",
        cancel: "Atcelt",
        confirm: "Dzēst",
      // Tooltips
      carBrandTooltip:
        "Izvēlieties automašīnu zīmolu, piemēram, Toyota, BMW vai Ford.",
      carModelTooltip:
        "Izvēlieties vienu vai vairākus konkrētus automašīnu modeļus, piemēram, 'Corolla' no Toyota vai 'Civic' no Honda.",
      priceFromTooltip: "Norādiet minimālo automašīnas cenu (no).",
      priceToTooltip: "Norādiet maksimālo automašīnas cenu (līdz).",
      yearFromTooltip: "Norādiet minimālo automašīnas izlaiduma gadu (no).",
      yearToTooltip: "Norādiet maksimālo automašīnas izlaiduma gadu (līdz).",
      colorTooltip:
        "Izvēlieties vienu vai vairākas automašīnas krāsas, piemēram, melnu, baltu vai sarkanu.",
      areaTooltip:
        "Izvēlieties vienu vai vairākas atrašanās vietas, kur vēlaties meklēt automašīnas, piemēram, pilsētu vai reģionu.",
      // Advanced tooltips
      engineTypeTooltip:
        "Izvēlieties vienu vai vairākus dzinēja veidus, piemēram, dīzeļa vai elektrisko.",
      bodyTypeTooltip:
        "Izvēlieties vienu vai vairākus virsbūves stilus, piemēram, sedans, apvidus auto vai hečbeks.",
      volumeFromTooltip: "Norādiet minimālo dzinēja tilpumu (no).",
      volumeToTooltip: "Norādiet maksimālo dzinēja tilpumu (līdz).",
      gearboxTooltip:
        "Izvēlieties ātrumkārbas veidu, piemēram, manuālo vai automātisko.",
      runDistanceFromTooltip: "Norādiet minimālo nobraukumu (no).",
      runDistanceToTooltip: "Norādiet maksimālo nobraukumu (līdz).",
      dealTypeTooltip:
        "Izvēlieties darījuma veidu, piemēram, pirkšanu, nomu vai īri.",
      checkupTooltip:
        "Izvēlieties minimālo derīgās tehniskās apskates datumu. 'Nav preferenču' rāda visas automašīnas neatkarīgi no apskates statusa.",
  
      enabled: "Ieslēgts",
      disabled: "Izslēgts",
      filters: "Filtri",
      howItWorks: "Pamācība",
      settings: "Iestatījumi",
      contactUs: "Kontakti",
      reportProblem: "Ziņot par problēmu",
      latvia: "Latviešu",
      english: "Angļu",
      russian: "Krievu",
      // Errors
      phoneNumberRequired: "Telefona numurs ir nepieciešams.",
      invalidTelegramUser:
        "Nederīgs Telegram lietotājs! Lūdzu, mēģiniet vēlreiz.",
      invalidTelegramAuth:
        "Nederīgi autentifikācijas dati! Lūdzu, mēģiniet vēlreiz.",
      phoneNumberInUse: "Šis telefona numurs jau ir reģistrēts.",
  
      saveTime: "Esi soli priekšā citiem",
      receiveDeals: "Noķer savu sapņu auto pirmais",
      customizeFilters:
        "Tavs laiks ir vērtīgs. Ļauj automātiskajai meklēšanai paveikt darbu Tavā vietā!",
      tryNow: "Izmēģināt",
  
      addBtn: "Pievienot",
      details: "Detalizētie filtri",
      signUp: "Reģistrēties",
      productScheme: "Produktu shēma",
      feedback: "Atsauksmes",
      products: "Meklētājs",
      account: "Konts",
      language: "Valoda",
      profile: "Iestatījumi",
      signOut: "Iziet",
      login: "Ienākt",
      product: "Transports",
      shareFeedback: "Dalieties savā atsauksmē",
      feedbackIntro:
        "Jūsu atsauksmes palīdz mums uzlabot. Mēs labprāt dzirdētu jūsu viedokli!",
      rateExperience: "Novērtējiet savu pieredzi",
      enterEmail: "Ievadiet savu e-pastu",
      emailPlaceholder: "jusu.epasts@piemers.com",
      leaveComment: "Atstājiet komentāru",
      feedbackPlaceholder: "Pastāstiet par savu pieredzi...",
      submitFeedback: "Iesniegt atsauksmi",
      thankYouFeedback: "Paldies par jūsu atsauksmi!",
      successMessage:
        "Mēs novērtējam, ka veltījāt laiku, lai dalītos ar savu viedokli. Jūsu atsauksmes palīdz mums uzlabot mūsu pakalpojumus.",
      close: "Aizvērt",
      logins: "Ienākt",
      email: "E-pasts",
      password: "Parole",
      noAccount: "Vai jums nav konta?",
      signUpHere: "Reģistrējieties šeit",
      changePassword: "Mainīt paroli",
      changeEmail: "Mainīt lietotājvārdu",
      changeTelegram: "Mainīt Telegram kontu",
      currentPlan: "Pašreizējais plāns",
      activeFilters: "Aktīvie filtri:",
      userPlan:
        "Lietotāja plāns nodrošina ierobežotu piekļuvi bez papildu funkcijām.",
      basicPlan: "Basic plāns ļauj izveidot un izmantot līdz 5 SS filtriem.",
      advancedPlan:
        "Advanced plāns piedāvā iespēju pārvaldīt līdz 10 SS filtriem.",
      adminPlan: "Adminiem ir neierobežota piekļuve visām funkcijām un iespējām.",
      cars: "Automašīnas",
      motorcycles: "Motocikli",
      spareParts: "Rezerves daļas",
      verifyTelegramTitle: "Verificējiet savu Telegram kontu",
      step1: "Lejupielādējiet un instalējiet",
      telegramApp: "Telegram aplikāciju",
      or: "vai apmeklējiet",
      telegramWeb: "Telegram Web",
      step2: "Piesakieties savā Telegram kontā.",
      step3: "Meklēšanas joslā atrodiet",
      step4: "Ierakstiet komandu",
      step4End:
        "čatā un jūs saņemsiet verifikācijas kodu. Ievadiet šo kodu zemāk.",
      inputCodePlaceholder: "Kods...",
      verifying: "Verificē...",
      verify: "Verificēt",
      successModalTitle: "Verifikācija veiksmīga",
      feedbackModalTitle: "Nepieciešams pieslēgties",
      successModalBody:
        "Verifikācija tika veiksmīgi pabeigta! Drīz jūs tiksiet novirzīts uz pieteikšanās lapu.",
      feedbackModalBody:
        "Lai iesniegtu atsauksmi, jums ir jābūt pieslēgtam savam profilam.",
      accessRestricted: "Filtru pievienošana ierobežota",
      upgradePlan: "Lūdzu, jauniniet savu plānu, lai pievienotu filtrus.",
      addNewFilter: "Pievienot jaunu filtru",
      inactiveFilters: "Neaktīvie filtri",
      activeFilterLimitReached: "Sasniegts aktīvo filtru limits",
      upgradeModalBody:
        "Jūs esat sasniedzis maksimālo aktīvo filtru limitu savam plānam.",
      upgrade: "Uzlabot",
      //filtriem mašīnas
      filterNameLabel: "Filtra nosaukums:",
      checkupAnyLabel: "Nav preferenču",
      filterNamePlaceholder: "Mans pielāgotais filtra nosaukums",
      carBrandLabel: "Automašīnas zīmols",
      carBrand: "Automašīnas zīmols...",
      carModelLabel: "Automašīnas modelis",
      carModel: "Automašīnas modelis...",
      priceLabel: "Cena:",
      priceFrom: "Cena no",
      priceF: "Cena no...",
      priceTo: "Cena līdz",
      priceT: "Cena līdz...",
      yearLabel: "Gads:",
      yearFrom: "Gads no",
      yearF: "Gads no...",
      yearTo: "Gads līdz",
      yearT: "Gads līdz...",
      engineTypeLabel: "Dzinēja veids",
      engineType: "Dzinēja veids...",
      engineVolumeLabel: "Dzinēja tilpums:",
      volumeFrom: "Tilpums no",
      volumeF: "Tilpums no...",
      volumeTo: "Tilpums līdz",
      volumeT: "Tilpums līdz...",
      gearboxLabel: "Ātrumkārba",
      gearbox: "Ātrumkārba...",
      bodyTypeLabel: "Virsbūves tips",
      bodyType: "Virsbūves tips...",
      colorLabel: "Krāsa",
      color: "Krāsa...",
      areaLabel: "Atrašanās vieta",
      area: "Atrašanās vieta...",
      dealTypeLabel: "Darījuma veids",
      dealType: "Darījuma veids...",
      runDistanceLabel: "Nobraukums:",
      runDistanceFrom: "Nobr. no",
      runDistanceF: "Nobraukums no...",
      runDistanceTo: "Nobr. līdz",
      runDistanceT: "Nobraukums līdz...",
      checkupLabel: "Tehniskā apskate",
      checkup: "Tehniskā apskate...",
      noPreferenceOption: "Nav preferenču",
      saveButton: "Saglabāt",
      savingText: "Saglabā...",
      deleteButton: "Dzēst",
      deleteConfirmationTitle: "Dzēšanas apstiprinājums",
      deleteConfirmationBody:
        "Vai tiešām vēlaties dzēst <b>{filterName}</b> filtru?",
      cancelButton: "Atcelt",
      confirmButton: "Apstiprināt",
      deletingText: "Dzēš...",
      inactiveFilterAlert:
        "Jauns filtrs izveidots kā neaktīvs. Sasniegts maksimālais aktīvo filtru limits.",
      createAccountTitle: "Reģistrēties",
      emailLabel: "E-pasts",
      usernameLabel: "Lietotājvārds",
      passwordLabel: "Parole",
      confirmPasswordLabel: "Apstiprināt paroli",
      signUpButton: "Reģistrēties",
      registeringText: "Reģistrē...",
      loginPrompt: "Vai jums jau ir konts?",
      loginLinkText: "Pieteikties šeit",
      // Kļūdu paziņojumi frontendā
      signUpErrorGeneric: "Reģistrācija neizdevās. Lūdzu, mēģiniet vēlreiz.", // Sign up kļūdu paziņojums
      telegramInvalidCode:
        "Nepareizs vai nederīgs kods. Lūdzu, mēģiniet vēlreiz.", // Telegram kļūdu paziņojums
      telegramGenericError: "Radās kļūda. Lūdzu, mēģiniet vēlreiz vēlāk.",
      verifySuccess: "Verifikācija veiksmīga.",
      verificationSuccessMessage:
        "Jūsu verifikācija ir veiksmīga. Jūs tiksiet novirzīts uz pieteikšanās lapu.",
      loginErrorInvalidDetails:
        "Neizdevās pieteikties. Lūdzu, pārbaudiet savus datus un mēģiniet vēlreiz.", // Login kļūdu paziņojums
      loginErrorConnection:
        "Savienojuma kļūda. Lūdzu, pārbaudiet savienojumu un mēģiniet vēlreiz.",
      // Sākas backend kļūdu pazīņojumi
      filterNameEmpty: "Filtra nosaukums nevar būt tukšs.",
      priceNegative: "Cenas nevar būt negatīvas.",
      volumeNegative: "Dzinēja tilpums nevar būt negatīvs.",
      runDistanceNegative: "Nobraukums nevar būt negatīvs.",
      priceRangeInvalid: "Minimālā cena nevar pārsniegt maksimālo cenu.",
      volumeRangeInvalid: "Minimālais dzinēja tilpums nevar pārsniegt maksimālo.",
      runRangeInvalid: "Minimālais nobraukums nevar pārsniegt maksimālo.",
      volumeZero: "Dzinēja tilpums nevar būt 0.",
      runZero: "Nobraukums nevar būt 0.",
      uniqueFilterName: "Filtra nosaukumam jābūt unikālam.",
      unexpectedError: "Radās neparedzēta kļūda.",
      yearOutOfRange: "Gadam jābūt intervālā no 1901 līdz 2025.",
      // feedback
      feedbackSubmitError:
        "Neizdevās iesniegt atsauksmi. Lūdzu, mēģiniet vēlreiz.",
      feedbackSubmitGenericError: "Radās kļūda, iesniedzot atsauksmi.",
      // api.js backend
      userNotAuthenticated: "Lietotājs nav autentificēts.",
      invalidEmailFormat: "Nederīgs e-pasta formāts.",
      invalidRoleSpecified: "Norādīta nederīga loma.",
      internalServerError: "Iekšēja servera kļūda.",
      //auth.js
      emailAlreadyInUse: "E-pasts jau tiek izmantots.",
      validUsernameFormat: "Nederīgs Lietotājvārda formāts.",
      usernameAlreadyInUse: "Konts ar šo Lietotājvārdu jau eksistē.",
      invalidUsernameFormat:
        "Lietotājvārds var saturēt tikai burtus, ciparus, '_', '-' un tam jābūt no 4 līdz 12 simboliem garam.",
      inappropriateUsername: "Lietotājvārds satur neatbilstošus vārdus.",
      usernameRequired: "Lietotājvārds ir nepieciešams.",
      passwordTooShort: "Parolei jābūt vismaz 8 rakstzīmēm.",
      passwordTooShortLogin: "Parolei jābūt vismaz 6 rakstzīmēm.",
      passwordMismatch: "Paroles nesakrīt.",
      signupSuccess: "Reģistrācija veiksmīga.",
      emailRequired: "E-pasts ir nepieciešams.",
      invalidCredentials: "Nederīgi pieteikšanās dati.",
      loginFailed: "Pieteikšanās neizdevās.",
      loginSuccess: "Pieteikšanās veiksmīga.",
      sessionActive: "Sesija ir aktīva.",
      sessionExpired: "Sesija ir beigusies.",
      viewPlans: "Skatīt plānus",
      darkMode: "Tumšais",
      lightMode: "Gaišais",
      themeMode: "Režīms",
      loading: "Ielādē...",
      phoneNumberLabel: "Telefona numurs",
      invalidPhoneNumber: "Nepareizs telefona numurs.",
      invalidPhoneNumberLength: "Nepareizs telefona numura garums.",
      invalidPhoneNumberFormat: "Nepareizs telefona numura formāts.",
      phoneNumberAlreadyInUse: "Šis telefona numurs jau ir reģistrēts.",
      countryLabel: "Valsts nummurs",
    },
    en: {
        filterLimitTitle: "Cannot Activate Filter",
        filterLimitMessage: "Activating this filter exceeds your active filter limit. Disable another filter or upgrade your plan.",
        ok: "OK",
        confirmDeleteTitle: "Delete",
        confirmDeleteMessage: "This action will <span class='highlight-text'>permanently delete</span> your filter and <span class='highlight-text'>cannot be undone</span>.",
        cancel: "Cancel",
        confirm: "Delete",
      // Tooltips
      carBrandTooltip: "Select car brand, such as Toyota, BMW, or Ford.",
      carModelTooltip:
        "Select one or more specific car models, like 'Corolla' from Toyota or 'Civic' from Honda.",
      priceFromTooltip: "Specify the minimum car price (from).",
      priceToTooltip: "Specify the maximum car price (to).",
      yearFromTooltip: "Specify the minimum car manufacturing year (from).",
      yearToTooltip: "Specify the maximum car manufacturing year (to).",
      colorTooltip:
        "Select one or more car colors, such as black, white, or red.",
      areaTooltip:
        "Select one or more locations where you want to search for cars, such as a city or region.",
      // Advanced tooltips
      engineTypeTooltip:
        "Select one or more engine types, such as diesel or electric.",
      bodyTypeTooltip:
        "Select one or more body styles, such as sedan, SUV, or hatchback.",
      volumeFromTooltip: "Specify the minimum engine volume (from).",
      volumeToTooltip: "Specify the maximum engine volume (to).",
      gearboxTooltip:
        "Choose the type of transmission, such as manual or automatic.",
      runDistanceFromTooltip: "Specify the minimum mileage (from).",
      runDistanceToTooltip: "Specify the maximum mileage (to).",
      dealTypeTooltip:
        "Select the type of deal, such as purchase, lease, or rent.",
      checkupTooltip:
        "Choose the earliest valid technical inspection date. 'No preference' will show cars regardless of inspection status.",
  
      enabled: "Enabled",
      disabled: "Disabled",
      filters: "Filters",
      howItWorks: "How it works",
      settings: "Settings",
      contactUs: "Contact Us",
      reportProblem: "Report a Problem",
      latvia: "Latvian",
      english: "English",
      russian: "Russian",
      phoneNumberRequired: "Phone number is required.",
      invalidTelegramUser: "Invalid Telegram user! Please try again.",
      invalidTelegramAuth:
        "Invalid Telegram authentication data! Please try again.",
      phoneNumberInUse: "This phone number is already registered.",
  
      saveTime: "Stay ahead of others",
      receiveDeals: "Be the first to catch your dream car",
      customizeFilters:
        "Your time is valuable. Let automatic search do the work for you!",
      tryNow: "Try Now",
  
      addBtn: "Add",
      details: "Advanced",
      signUp: "Sign Up",
      productScheme: "Product Scheme",
      feedback: "Feedback",
      products: "Search engine",
      account: "Account",
      language: "Language",
      profile: "Settings",
      signOut: "Sign out",
      login: "Login",
      product: "Transport",
      shareFeedback: "Share your Feedback",
      feedbackIntro: "Your input helps us improve. We'd love to hear from you!",
      rateExperience: "Rate your experience",
      enterEmail: "Enter your email",
      emailPlaceholder: "youremail@example.com",
      leaveComment: "Leave a comment",
      feedbackPlaceholder: "Tell us about your experience...",
      submitFeedback: "Submit Feedback",
      thankYouFeedback: "Thank you for your feedback!",
      successMessage:
        "We appreciate you taking the time to share your thoughts. Your feedback helps us improve our services.",
      close: "Close",
      logins: "Login",
      email: "Email",
      password: "Password",
      noAccount: "Don't have an account?",
      signUpHere: "Sign Up here",
      changePassword: "Change Password",
      changeEmail: "Change Username",
      changeTelegram: "Change Telegram Account",
      currentPlan: "Current Plan",
      activeFilters: "Active Filters:",
      userPlan:
        "The User plan provides limited access, with no additional features or benefits.",
      basicPlan:
        "The Basic plan allows the creation and usage of up to 5 SS filters.",
      advancedPlan:
        "The Advanced plan offers the ability to create and manage up to 10 SS filters.",
      adminPlan:
        "Admins have unrestricted access to all features and functionalities.",
      cars: "Cars",
      motorcycles: "Motorcycles",
      spareParts: "Spare Parts",
      verifyTelegramTitle: "Verify your Telegram account",
      step1: "Download and install",
      telegramApp: "Telegram app",
      or: "or visit",
      telegramWeb: "Telegram Web",
      step2: "Sign in to your Telegram account.",
      step3: "In the search bar, look for",
      step4: "Type the command",
      step4End:
        "in the chat, and you'll receive a verification code. Enter that code below.",
      inputCodePlaceholder: "Code...",
      verifying: "Verifying...",
      verify: "Verify",
      successModalTitle: "Verification Successful",
      feedbackModalTitle: "It is necessary to connect",
      successModalBody:
        "Verification was successful! You will be redirected to the login page shortly.",
      feedbackModalBody:
        "You must be logged in to your profile to submit a review.",
      accessRestricted: "Adding filters restricted",
      upgradePlan: "Please upgrade your plan to add filters.",
      addNewFilter: "Add new filter",
      inactiveFilters: "Inactive filters",
      activeFilterLimitReached: "Active Filter Limit Reached",
      upgradeModalBody:
        "You have reached the maximum limit of active filters for your plan.",
      upgrade: "Upgrade",
      filterNameLabel: "Filter name:",
      checkupAnyLabel: "Any",
      filterNamePlaceholder: "My custom filter name",
      carBrandLabel: "Car brand",
      carBrand: "Car brand...",
      carModelLabel: "Car Model",
      carModel: "Car model...",
      priceLabel: "Price:",
      priceFrom: "Price from",
      priceF: "Price from...",
      priceTo: "Price to",
      priceT: "Price to...",
      yearLabel: "Year:",
      yearFrom: "Year from",
      yearF: "Year from...",
      yearTo: "Year to",
      yearT: "Year to...",
      engineTypeLabel: "Engine Type",
      engineType: "Engine type...",
      engineVolumeLabel: "Engine volume:",
      volumeFrom: "Volume from",
      volumeF: "Volume from...",
      volumeTo: "Volume to",
      volumeT: "Volume to...",
      gearboxLabel: "Gearbox",
      gearbox: "Gearbox...",
      bodyTypeLabel: "Body Type",
      bodyType: "Body type...",
      colorLabel: "Color",
      color: "Color...",
      areaLabel: "Area",
      area: "Area...",
      dealTypeLabel: "Deal type",
      dealType: "Deal type...",
      runDistanceLabel: "Run Distance:",
      runDistanceFrom: "Run from",
      runDistanceF: "Run from...",
      runDistanceTo: "Run to",
      runDistanceT: "Run to...",
      checkupLabel: "Checkup",
      checkup: "Checkup...",
      noPreferenceOption: "No Preference",
      saveButton: "Save",
      savingText: "Saving...",
      deleteButton: "Delete",
      deleteConfirmationTitle: "Delete Confirmation",
      deleteConfirmationBody:
        "Are you sure you want to delete <b>{filterName}</b> filter?",
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      deletingText: "Deleting...",
      inactiveFilterAlert:
        "New filter created as inactive. Maximum active filter limit reached.",
      createAccountTitle: "Register",
      emailLabel: "Email",
      usernameLabel: "Username",
      passwordLabel: "Password",
      confirmPasswordLabel: "Confirm Password",
      signUpButton: "Sign Up",
      registeringText: "Registering...",
      loginPrompt: "Already have an account?",
      loginLinkText: "Login here",
      signUpErrorGeneric: "Registration failed. Please try again.",
      telegramInvalidCode: "Invalid or expired code. Please try again.",
      telegramGenericError: "An error occurred. Please try again later.",
      verifySuccess: "Verification successful.",
      verificationSuccessMessage:
        "Your verification is successful. You will be redirected to the login page.",
      loginErrorInvalidDetails:
        "Failed to login. Please check your details and try again.",
      loginErrorConnection:
        "Connection error. Please check your connection and try again.",
      // Sākas backend kļūdu pazīņojumi
      filterNameEmpty: "Filter name cannot be empty.",
      priceNegative: "Prices cannot be negative.",
      volumeNegative: "Engine volume cannot be negative.",
      runDistanceNegative: "Run distances cannot be negative.",
      priceRangeInvalid: "Minimum price cannot exceed maximum price.",
      volumeRangeInvalid: "Minimum engine volume cannot exceed maximum volume.",
      runRangeInvalid: "Minimum run distance cannot exceed maximum distance.",
      volumeZero: "Engine volume cannot be 0.",
      runZero: "Run distance cannot be 0.",
      uniqueFilterName: "Filter name must be unique.",
      unexpectedError: "An unexpected error occurred.",
      yearOutOfRange: "The year must be between 1901 and 2025.",
      feedbackSubmitError: "Failed to submit feedback. Please try again.",
      feedbackSubmitGenericError: "An error occurred while submitting feedback.",
      userNotAuthenticated: "User not authenticated.",
      invalidEmailFormat: "Invalid email format.",
      invalidRoleSpecified: "Invalid role specified.",
      internalServerError: "Internal server error.",
      emailAlreadyInUse: "Email already in use.",
      validUsernameFormat: "Invalid Username Format.",
      usernameAlreadyInUse: "Account with this Username already exists.",
      invalidUsernameFormat:
        "Lietotājvārds var saturēt tikai burtus, ciparus, '_', '-' un tam jābūt no 4 līdz 12 simboliem garam.",
      inappropriateUsername: "Username contains inappropriate words.",
      usernameRequired: "Username is required.",
      passwordTooShort: "Password must be at least 8 characters long.",
      passwordTooShortLogin: "Password must be at least 6 characters.",
      passwordMismatch: "Passwords do not match.",
      signupSuccess: "Signup successful.",
      emailRequired: "Email is required.",
      invalidCredentials: "Invalid credentials.",
      loginFailed: "Login failed.",
      loginSuccess: "Login successful.",
      sessionActive: "Session is active.",
      sessionExpired: "Session has expired.",
      viewPlans: "View plans",
      darkMode: "Dark",
      lightMode: "Light",
      themeMode: "Mode",
      loading: "Loading...",
      phoneNumberLabel: "Phone Number",
      invalidPhoneNumber: "Invalid phone number.",
      invalidPhoneNumberLength: "Invalid phone number length.",
      invalidPhoneNumberFormat: "Invalid phone number format.",
      phoneNumberAlreadyInUse: "This phone number is already registered.",
      countryLabel: "Country code",
    },
    ru: {
        filterLimitTitle: "Невозможно активировать фильтр",
        filterLimitMessage: "Активация этого фильтра превышает лимит активных фильтров. Отключите другой фильтр или обновите план.",
        ok: "OK",
        confirmDeleteTitle: "Удалить",
        confirmDeleteMessage: "Это действие <span class='highlight-text'>навсегда удалит</span> ваш фильтр и <span class='highlight-text'>не может быть отменено</span>.",
        cancel: "Отмена",
        confirm: "Удалить",
      // Tooltips
      carBrandTooltip:
        "Выберите бренд автомобиля, например, Toyota, BMW или Ford.",
      carModelTooltip:
        "Выберите одну или несколько конкретных моделей автомобилей, например, 'Corolla' от Toyota или 'Civic' от Honda.",
      priceFromTooltip:
        "Укажите минимальную цену (от) для автомобиля, который вы ищете.",
      priceToTooltip:
        "Укажите максимальную цену (до) для автомобиля, который вы ищете.",
      yearFromTooltip:
        "Укажите минимальный год выпуска (от) автомобиля, который вы ищете.",
      yearToTooltip:
        "Укажите максимальный год выпуска (до) автомобиля, который вы ищете.",
      colorTooltip:
        "Выберите один или несколько цветов автомобиля, который вы ищете, например, черный, белый или красный.",
      areaTooltip:
        "Выберите одно или несколько мест, где вы хотите искать автомобили, например, города или регионы.",
      // Advanced tooltips
      engineTypeTooltip:
        "Выберите один или несколько типов двигателя, например, бензиновый, дизельный или электрический.",
      bodyTypeTooltip:
        "Выберите один или несколько типов кузова, например, седан, внедорожник или хэтчбек.",
      volumeFromTooltip:
        "Укажите минимальный объем двигателя (от), который вы ищете.",
      volumeToTooltip:
        "Укажите максимальный объем двигателя (до), который вы ищете.",
      gearboxTooltip:
        "Выберите тип коробки передач, например, механическая или автоматическая.",
      runDistanceFromTooltip:
        "Укажите минимальный пробег (от) для автомобиля, который вы ищете.",
      runDistanceToTooltip:
        "Укажите максимальный пробег (до) для автомобиля, который вы ищете.",
      dealTypeTooltip:
        "Выберите тип сделки, например, покупка автомобиля, аренда или прокат.",
      checkupTooltip:
        "Укажите дату последнего действующего технического осмотра. Выбор 'Без предпочтений' покажет автомобили с действующим или без действующего осмотра.",
  
      enabled: "Включено",
      disabled: "Выключено",
      filters: "Фильтры",
      howItWorks: "Как это работает",
      settings: "Настройки",
      contactUs: "Связаться с нами",
      reportProblem: "Сообщить о проблеме",
      latvia: "Латышский",
      english: "Английский",
      russian: "Русский",
      phoneNumberRequired: "Требуется номер телефона.",
      invalidTelegramUser: "Неверный пользователь Telegram!",
      invalidTelegramAuth:
        "Неверные данные аутентификации Telegram! Попробуйте снова.",
      phoneNumberInUse: "Этот номер телефона уже зарегистрирован.",
      saveTime: "Сэкономьте свое время",
      receiveDeals: "Получайте желаемые предложения сразу после их появления.",
      customizeFilters:
        "Настройте свои фильтры, получайте персонализированные предложения и будьте первым, кто их использует.",
      tryNow: "Попробовать",
      addBtn: "Добавлять",
      details: "Подробные фильтры",
      signUp: "Зарегистрироваться",
      productScheme: "Схема продукта",
      feedback: "Отзывы",
      products: "Поисковая система",
      account: "Аккаунт",
      language: "Язык",
      profile: "Настройки",
      signOut: "Выйти",
      login: "Вход",
      product: "Транспорт",
      shareFeedback: "Поделитесь своим отзывом",
      feedbackIntro:
        "Ваши отзывы помогают нам стать лучше. Нам важно ваше мнение!",
      rateExperience: "Оцените ваш опыт",
      enterEmail: "Введите ваш email",
      emailPlaceholder: "ваш.почта@пример.com",
      leaveComment: "Оставьте комментарий",
      feedbackPlaceholder: "Расскажите о вашем опыте...",
      submitFeedback: "Отправить отзыв",
      thankYouFeedback: "Спасибо за ваш отзыв!",
      successMessage:
        "Мы ценим ваше время и ваше мнение. Ваш отзыв помогает нам улучшить наши услуги.",
      close: "Закрыть",
      logins: "Войти",
      email: "Электронная почта",
      password: "Пароль",
      noAccount: "У вас нет аккаунта?",
      signUpHere: "Зарегистрируйтесь здесь",
      changePassword: "Изменить пароль",
      changeEmail: "Изменить имя пользователя",
      changeTelegram: "Изменить аккаунт Telegram",
      currentPlan: "Текущий план",
      activeFilters: "Активные фильтры:",
      userPlan:
        "План пользователя предоставляет ограниченный доступ без дополнительных функций.",
      basicPlan:
        "Базовый план позволяет создать и использовать до 5 SS фильтров.",
      advancedPlan:
        "Расширенный план предоставляет возможность управлять до 10 SS фильтрами.",
      adminPlan: "Администраторы имеют неограниченный доступ ко всем функциям.",
      cars: "Машины",
      motorcycles: "Мотоциклы",
      spareParts: "Запчасти",
      verifyTelegramTitle: "Подтвердите свой аккаунт Telegram",
      step1: "Скачайте и установите",
      telegramApp: "приложение Telegram",
      or: "или посетите",
      telegramWeb: "Telegram Web",
      step2: "Войдите в свой аккаунт Telegram.",
      step3: "В строке поиска найдите",
      step4: "Введите команду",
      step4End: "в чате, и вы получите код подтверждения. Введите этот код ниже.",
      inputCodePlaceholder: "Kод...",
      verifying: "Проверка...",
      verify: "Подтвердить",
      successModalTitle: "Подтверждение успешно",
      feedbackModalTitle: "Необходимо подключить",
      successModalBody:
        "Подтверждение успешно завершено! Вы скоро будете перенаправлены на страницу входа.",
      feedbackModalBody: "Вы должны войти в свой профиль, чтобы оставить отзыв.",
      accessRestricted: "Добавление фильтров ограничено",
      upgradePlan: "Пожалуйста, обновите свой план, чтобы добавить фильтры.",
      addNewFilter: "Добавить новый фильтр",
      inactiveFilters: "Неактивные фильтры",
      activeFilterLimitReached: "Достигнут лимит активных фильтров",
      upgradeModalBody:
        "Вы достигли максимального лимита активных фильтров для вашего плана.",
      upgrade: "Обновить",
      filterNameLabel: "Название фильтра:",
      checkupAnyLabel: "Любой",
      filterNamePlaceholder: "Мое пользовательское имя фильтра",
      carBrandLabel: "Марка автомобиля",
      carBrand: "Марка автомобиля...",
      carModelLabel: "Модель автомобиля",
      carModel: "Модель автомобиля...",
      priceLabel: "Цена:",
      priceFrom: "Цена от",
      priceF: "Цена от...",
      priceTo: "Цена до",
      priceT: "Цена до...",
      yearLabel: "Год:",
      yearFrom: "Год от",
      yearF: "Год от...",
      yearTo: "Год до",
      yearT: "Год до...",
      engineTypeLabel: "Тип двигателя",
      engineType: "Тип двигателя...",
      engineVolumeLabel: "Объем двигателя:",
      volumeFrom: "Объем от",
      volumeF: "Объем от...",
      volumeTo: "Объем до",
      volumeT: "Объем до...",
      gearboxLabel: "Коробка передач",
      gearbox: "Коробка передач...",
      bodyTypeLabel: "Тип кузова",
      bodyType: "Тип кузова...",
      colorLabel: "Цвет",
      color: "Цвет...",
      areaLabel: "Местоположение",
      area: "Местоположение...",
      dealTypeLabel: "Тип сделки",
      dealType: "Тип сделки...",
      runDistanceLabel: "Пробег:",
      runDistanceFrom: "Пробег от",
      runDistanceF: "Пробег от...",
      runDistanceTo: "Пробег до",
      runDistanceT: "Пробег до...",
      checkupLabel: "Техосмотр",
      checkup: "Техосмотр...",
      noPreferenceOption: "Без предпочтений",
      // buttons
      saveButton: "Сохранить",
      savingText: "Сохраняет...",
      deleteButton: "Удалить",
      deleteConfirmationTitle: "Подтверждение удаления",
      deleteConfirmationBody:
        "Вы уверены, что хотите удалить фильтр <b>{filterName}</b>?",
      cancelButton: "Отмена",
      confirmButton: "Подтвердить",
      deletingText: "Удаляет...",
      inactiveFilterAlert:
        "Новый фильтр создан как неактивный. Достигнут максимальный лимит активных фильтров.",
      createAccountTitle: "Создать аккаунт",
      emailLabel: "Электронная почта",
      usernameLabel: "Имя пользователя",
      passwordLabel: "Пароль",
      confirmPasswordLabel: "Подтвердите пароль",
      signUpButton: "Зарегистрироваться",
      registeringText: "Регистрация...",
      loginPrompt: "Уже есть аккаунт?",
      loginLinkText: "Войти здесь",
      signUpErrorGeneric:
        "Регистрация не удалась. Пожалуйста, попробуйте еще раз.",
      telegramInvalidCode:
        "Неверный или истёкший код. Пожалуйста, попробуйте ещё раз.",
      telegramGenericError: "Произошла ошибка. Пожалуйста, попробуйте позже.",
      verifySuccess: "Подтверждение успешно.",
      verificationSuccessMessage:
        "Ваше подтверждение успешно. Вы будете перенаправлены на страницу входа.",
      loginErrorInvalidDetails:
        "Не удалось войти. Пожалуйста, проверьте свои данные и попробуйте снова.",
      loginErrorConnection:
        "Ошибка соединения. Пожалуйста, проверьте соединение и попробуйте снова.",
      // Sākas backend kļūdu pazīņojumi
      filterNameEmpty: "Название фильтра не может быть пустым.",
      priceNegative: "Цены не могут быть отрицательными.",
      volumeNegative: "Объем двигателя не может быть отрицательным.",
      runDistanceNegative: "Пробег не может быть отрицательным.",
      priceRangeInvalid: "Минимальная цена не может превышать максимальную.",
      volumeRangeInvalid:
        "Минимальный объем двигателя не может превышать максимальный.",
      runRangeInvalid: "Минимальный пробег не может превышать максимальный.",
      volumeZero: "Объем двигателя не может быть 0.",
      runZero: "Пробег не может быть 0.",
      uniqueFilterName: "Название фильтра должно быть уникальным.",
      unexpectedError: "Произошла непредвиденная ошибка.",
      yearOutOfRange: "Год должен быть между 1901 и 2025.",
      feedbackSubmitError:
        "Не удалось отправить отзыв. Пожалуйста, попробуйте еще раз.",
      feedbackSubmitGenericError: "Произошла ошибка при отправке отзыва.",
      userNotAuthenticated: "Пользователь не авторизован.",
      invalidEmailFormat: "Недопустимый формат электронной почты.",
      invalidRoleSpecified: "Указана недопустимая роль.",
      internalServerError: "Внутренняя ошибка сервера.",
      emailAlreadyInUse: "Электронная почта уже используется.",
      validUsernameFormat: "Неверный формат имени пользователя.",
      usernameAlreadyInUse:
        "Учетная запись с таким именем пользователя уже существует.",
      invalidUsernameFormat:
        "Имя пользователя может содержать только буквы, цифры, '_', '-' и должно иметь длину от 4 до 12 символов.",
      inappropriateUsername: "Имя пользователя содержит недопустимые слова.",
      usernameRequired: "Требуется имя пользователя.",
      passwordTooShort: "Пароль должен быть не менее 8 символов.",
      passwordTooShortLogin: "Пароль должен быть не менее 6 символов.",
      passwordMismatch: "Пароли не совпадают.",
      signupSuccess: "Регистрация успешна.",
      emailRequired: "Требуется электронная почта.",
      invalidCredentials: "Неверные учетные данные.",
      loginFailed: "Ошибка входа.",
      loginSuccess: "Вход выполнен успешно.",
      sessionActive: "Сессия активна.",
      sessionExpired: "Сессия истекла.",
      viewPlans: "Тарифы",
      darkMode: "Темный",
      lightMode: "Светлый",
      themeMode: "Режим",
      loading: "Загрузка...",
      phoneNumberLabel: "Номер телефона:",
      invalidPhoneNumber: "Недопустимый номер телефона.",
      invalidPhoneNumberLength: "Недопустимая длина номера телефона.",
      invalidPhoneNumberFormat: "Недопустимый формат номера телефона.",
      phoneNumberAlreadyInUse: "Этот номер телефона уже зарегистрирован.",
      countryLabel: "Код страны",
    },
  };
  
  module.exports = translations;
  