import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'; 
import HomePage from './pages/home/HomePage';
import FiltersPage from './pages/Filters/FiltersPage';
import FiltersFormPage from './pages/Filters/FiltersFormPage';
// import LoginPage from './LoginPage';
// import TelegramVerifyPage from './TelegramVerifyPage';
// import SignUpPage from './SignUpPage';
import AdminPage from './pages/Admin/AdminPage';
import AdminConsole from './pages/Admin/AdminConsole';
// import ProductsPage from './ProductsPage';
// import ViewFeedbacks from './ViewFeedbacks';
import MainPage from './pages/home/MainPage';
// import MotorcyclesPage from './MotorcyclesPage'; 
// import SparePartsPage from './SparePartsPage'; 
import { ThemeProvider } from './ThemeContext';
import axios from 'axios';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [user, setUser] = useState(null);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Pārbauda, vai lietotājs izmanto Telegram Mini App
  const isMiniApp = Boolean(
    typeof window !== "undefined" &&
    window.Telegram?.WebApp &&
    (navigator.userAgent.includes("Telegram") || Object.keys(window.Telegram.WebApp.initDataUnsafe || {}).length > 0)
  );

  useEffect(() => {
    if (!isMiniApp) {
      const allowedPaths = ['/'];

      if (!allowedPaths.includes(location.pathname)) {
        navigate('/', { replace: true });
      }
    }
  }, [location, navigate, isMiniApp]);
  
  // const isMiniApp = Boolean(
  //   typeof window !== "undefined" &&
  //   window.Telegram?.WebApp &&
  //   (navigator.userAgent.includes("Telegram") || Object.keys(window.Telegram.WebApp.initDataUnsafe || {}).length > 0)
  // );

  useEffect(() => {
    let isMounted = true;
  
    const checkLoginStatus = async () => {
      try {
        const res = await fetch('/api/auth/status', { credentials: 'include' });
        if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
    
        const data = await res.json();
    
        if (isMounted) {
          if (data.isLoggedIn) {
            setIsLoggedIn(true);
            setUser(data.user);
            
            // Pārbauda, vai ir filtri, un novirza uz /filters/new, ja nav neviena filtra
            const filtersResponse = await axios.get('/filters/get/filters');
            const formattedFilters = Object.keys(filtersResponse.data).map(filterId => ({
              filterId,
              name: filtersResponse.data[filterId][0].filterName || "Unnamed Filter",
              active: filtersResponse.data[filterId][0].active,
            }));

            if (formattedFilters.length === 0 && redirectAfterLogin) {
              navigate('/filters/new', { replace: true });
              setRedirectAfterLogin(false); // Atzīmē, ka pāradresācija jau ir notikusi
            } else if (redirectAfterLogin) {
              navigate('/filters', { replace: true });
              setRedirectAfterLogin(false);
            }
          } else {
            await autoLoginTelegramUser();
          }
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
      } finally {
        if (isMounted) setIsCheckingAuth(false);
      }
    };

    const autoLoginTelegramUser = async () => {
      if (window.Telegram?.WebApp) {
        const tg = window.Telegram.WebApp;
        tg.ready();
        tg.expand();

        const initData = tg.initDataUnsafe;
        console.log("Telegram Init Data:", initData);

        if (initData?.user?.id) {
          const telegramUserId = initData.user.id;
          const firstName = initData.user.first_name || "User";
          
          console.log(`Extracted Telegram User ID: ${telegramUserId}`);

          try {
            const response = await axios.post(
              '/auth/login', 
              { telegram_user_id: telegramUserId, initData: tg.initData }, 
              { withCredentials: true }
            );

            if (response.data?.user) {
              localStorage.setItem('userSession', JSON.stringify(response.data.user));
              setIsLoggedIn(true);
              setUser(response.data.user);

              // Pārbauda, vai ir filtri, un novirza uz /filters/new, ja nav neviena filtra
              const filtersResponse = await axios.get('/filters/get/filters');
              const formattedFilters = Object.keys(filtersResponse.data).map(filterId => ({
                filterId,
                name: filtersResponse.data[filterId][0].filterName || "Unnamed Filter",
                active: filtersResponse.data[filterId][0].active,
              }));

              if (formattedFilters.length === 0 && redirectAfterLogin) {
                navigate('/filters/new', { replace: true });
                setRedirectAfterLogin(false); // Atzīmē, ka pāradresācija jau ir notikusi
              } else if (redirectAfterLogin) {
                navigate('/filters', { replace: true });
                setRedirectAfterLogin(false);
              }
            } else {
              console.warn('Auto-login failed, attempting to sign up user');
              await autoRegisterTelegramUser(telegramUserId, firstName);
            }
          } catch (error) {
            console.error('Auto-login failed:', error.response?.data?.message || error.message);
          }
        } else {
          console.warn("No Telegram user data found in `initDataUnsafe`!");
        }
      }
    };

    const autoRegisterTelegramUser = async (telegramUserId, firstName) => {
      try {
        const response = await axios.post(
          '/auth/signup',
          { telegram_user_id: telegramUserId, first_name: firstName, initData: window.Telegram.WebApp.initData },
          { withCredentials: true }
        );

        if (response.data?.user) {
          localStorage.setItem('userSession', JSON.stringify(response.data.user));
          setIsLoggedIn(true);
          setUser(response.data.user);

          // Pārbauda, vai ir filtri, un novirza uz /filters/new, ja nav neviena filtra
          const filtersResponse = await axios.get('/filters/get/filters');
          const formattedFilters = Object.keys(filtersResponse.data).map(filterId => ({
            filterId,
            name: filtersResponse.data[filterId][0].filterName || "Unnamed Filter",
            active: filtersResponse.data[filterId][0].active,
          }));

          if (formattedFilters.length === 0 && redirectAfterLogin) {
            navigate('/filters/new', { replace: true });
            setRedirectAfterLogin(false); // Atzīmē, ka pāradresācija jau ir notikusi
          } else if (redirectAfterLogin) {
            navigate('/filters', { replace: true });
            setRedirectAfterLogin(false);
          }
        } else {
          console.warn('Auto-registration failed: No user returned from API');
        }
      } catch (error) {
        console.error('Auto-registration failed:', error.response?.data?.message || error.message);
      }
    };

    checkLoginStatus();

    return () => {
      isMounted = false;
    };
  }, [navigate, redirectAfterLogin]);

  if (isCheckingAuth) {
    return <div>Loading...</div>;
  }

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  return (
    <ThemeProvider>
      <Routes>
      <Route path='/api/filters/get' element={<Navigate to="/filters" />} /> 
        <Route path='/' element={<HomePage />} />
        <Route path='/filters' element={isMiniApp ? <FiltersPage /> : <Navigate to="/" />} />
        <Route path="/filters/new" element={isMiniApp ? <FiltersFormPage /> : <Navigate to="/" />} />
        <Route path="/filters/update/:filterId" element={isMiniApp ? <FiltersFormPage /> : <Navigate to="/" />} />
        {/* <Route path='/products' element={isMiniApp ? <ProductsPage /> : <Navigate to="/" />} /> */}
        <Route path='/main' element={<MainPage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/admin/console' element={<AdminConsole />} />
        {/* <Route path="/admin/feedbacks" element={<ViewFeedbacks />} /> */}
        {/* <Route path='/motorcycles' element={isMiniApp ? <MotorcyclesPage /> : <Navigate to="/" />} /> */}
        {/* <Route path='/spare-parts' element={isMiniApp ? <SparePartsPage /> : <Navigate to="/" />} />      */}
      </Routes> 
    </ThemeProvider>
  );
} 

export default App;