import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Spinner, Alert, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate, useParams  } from 'react-router-dom';
import axios from 'axios';
import translations from '../../translate.js';
import { useTheme } from '../../ThemeContext.js';
import arrowRightIcon from '../../img/arrow-right.svg';
import arrowDownIcon from '../../img/arrow-down.svg';
import trashIcon from '../../img/trashIcon.svg';
import saveIcon from '../../img/saveIcon.svg';
import DropdownIcon from '../../img/Dropdown.svg';
import backIcon from '../../img/Back.svg';
import carData from '../../ssData/dropdownData/carData.json';
import pluss from '../../img/pluss1.svg';
// import './styles/FiltersForm.scss';
import '../../styles/FiltersForm.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons'; 


function FiltersFormPage() {
    const { filterId } = useParams();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showVolume, setShowVolume] = useState(false);
  const language = localStorage.getItem('language') || 'lv';
  const text = translations[language];

  const [filterData, setFilterData] = useState({
    filterName: 'Filter',
    priceMin: null,
    priceMax: null,
    yearMin: '-',
    yearMax: '-',
    volumeMin: null,
    volumeMax: null,
    runMin: null,
    runMax: null,
    carBrand: "0",
    gearBox: "0",
    checkup: null,
    dealType: "0",
    carModel: [],  
    engine: [],    
    bodyType: [],  
    color: [],     
    area: []  
  });

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showCarModelDropdown, setShowCarModelDropdown] = useState(false);
  const [showColorDropdown, setShowColorDropdown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showAreaDropdown, setShowAreaDropdown] = useState(false);
  const [showEngineDropdown, setShowEngineDropdown] = useState(false);
  const [showBodyTypeDropdown, setShowBodyTypeDropdown] = useState(false);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [pendingActiveStatus, setPendingActiveStatus] = useState(null);
  const [maxFiltersReached, setMaxFiltersReached] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [filterLimit, setFilterLimit] = useState(0);
  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const carModelDropdownRef = useRef(null);
  const colorDropdownRef = useRef(null);
  const areaDropdownRef = useRef(null);
  const engineDropdownRef = useRef(null);
  const bodyTypeDropdownRef = useRef(null);

// Funkcija, lai iegūtu filtru limitu, balstoties uz lomu
const getFilterLimitForRole = (role) => {
    switch (role) {
        case 'admin': return 20;
        case 'advanced': return 10;
        case 'basic': return 5;
        default: return 0;
    }
};

// Ielādē lietotāja lomu no API
useEffect(() => {
    const fetchUserRole = async () => {
        try {
            const response = await axios.get('/filters/get/filter-limit', { withCredentials: true });
            const role = response.data.role;
            setUserRole(role);
        } catch (error) {
            console.error('Error fetching user role:', error);
        }
    };

    fetchUserRole();
}, []);

// Atjauno filtru limitu, kad userRole mainās
useEffect(() => {
    if (!userRole) return; 
    setFilterLimit(getFilterLimitForRole(userRole));
}, [userRole]);

// Pārbauda filtru limitu
useEffect(() => {
    const checkFilterLimit = async () => {
        try {
            const response = await axios.get('/filters/get/combined-count', { withCredentials: true });
            const { combinedCount } = response.data;

            if (userRole) {
                const filterLimit = getFilterLimitForRole(userRole);

                if (combinedCount >= filterLimit) {
                    setMaxFiltersReached(true);
                    setIsActive(false); 
                } else {
                    setMaxFiltersReached(false);
                    setIsActive(true);
                }
            }
        } catch (error) {
            console.error('Error checking filter limit:', error);
        }
    };

    if (!filterId && userRole) { 
        checkFilterLimit();
    }
}, [filterId, userRole]); 


  // Funkcija, kas sāk nosaukuma rediģēšanu
  const startEditing = () => {
      setIsEditing(true);
  };
  
  const stopEditing = () => {
      if (!filterData.filterName.trim()) {
          setFilterData((prev) => ({
              ...prev,
              filterName: "Filter Name"
          }));
      }
      setIsEditing(false);
  };

  const handleFilterNameChange = (e) => {
      const newValue = e.target.value;
      setFilterData((prev) => ({
          ...prev,
          filterName: newValue
      }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let updatedFilters = {
        ...filterData,
        [name]: value === "0" ? null : value, 
    };

    
    if (name === 'carBrand') {
        updatedFilters = {
            ...updatedFilters,
            carModel: [], 
        };
    }
    
    if (name === 'engine' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            engine: []  
        };
    }
    
    if (name === 'bodyType' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            bodyType: [] 
        };
    }
    
    if (name === 'color' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            color: [] 
        };
    }
    
    if (name === 'area' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            area: [] 
        };
    }
    
    setFilterData(updatedFilters);
};

  const handleAddCarModel = (modelValue) => {
    if (!filterData.carModel.includes(modelValue)) {
        setFilterData((prevFilters) => ({
        ...prevFilters,
        carModel: [...prevFilters.carModel, modelValue],
      }));
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, false); // Aizver dropdown
    }
  };
  const handleRemoveCarModel = (modelValue) => {
    setFilterData((prevFilters) => ({
      ...prevFilters,
      carModel: prevFilters.carModel.filter((model) => model !== modelValue),
    }));
  };

  useEffect(() => {
      setShowVolume(!(filterData.engine.length === 1 && filterData.engine.includes("electric")));
  }, [filterData.engine]);
  

  const handleAddEngine = (engineValue) => {
    if (!filterData.engine.includes(engineValue)) {
        setFilterData((prevFilters) => ({
        ...prevFilters,
        engine: [...prevFilters.engine, engineValue],
      }));
      openDropdown(setShowEngineDropdown, engineDropdownRef, false); // Aizver dropdown
    }
  };

const handleRemoveEngine = (engineToRemove) => {
    setFilterData((prevFilters) => ({
        ...prevFilters,
        engine: prevFilters.engine.filter((engine) => engine !== engineToRemove),
    }));
};


const handleAddBodyType = (bodyTypeValue) => {
    if (!filterData.bodyType.includes(bodyTypeValue)) {
        setFilterData((prevFilters) => ({
        ...prevFilters,
        bodyType: [...prevFilters.bodyType, bodyTypeValue],
      }));
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false); // Aizver dropdown
    }
  };
  
  const handleRemoveBodyType = (bodyTypeValue) => {
    setFilterData((prevFilters) => ({
      ...prevFilters,
      bodyType: prevFilters.bodyType.filter((bodyType) => bodyType !== bodyTypeValue),
    }));
  };
  
  const handleAddColor = (colorValue) => {
    if (!filterData.color.includes(colorValue)) {
        setFilterData((prevFilters) => ({
        ...prevFilters,
        color: [...prevFilters.color, colorValue],
      }));
      openDropdown(setShowColorDropdown, colorDropdownRef, false); // Aizver dropdown
    }
  };
  
  const handleRemoveColor = (colorValue) => {
    setFilterData((prevFilters) => ({
      ...prevFilters,
      color: prevFilters.color.filter((color) => color !== colorValue),
    }));
  };
  const handleAddArea = (areaValue) => {
    if (!filterData.area.includes(areaValue)) {
        setFilterData((prevFilters) => ({
        ...prevFilters,
        area: [...prevFilters.area, areaValue],
      }));
      openDropdown(setShowAreaDropdown, areaDropdownRef, false); 
    }
  };
  
  const handleRemoveArea = (areaValue) => {
    setFilterData((prevFilters) => ({
      ...prevFilters,
      area: prevFilters.area.filter((area) => area !== areaValue),
    }));
  };

  const toggleDetails = () => {
    setIsDetailsExpanded(!isDetailsExpanded);
  };

  const generateCheckUpMonths = () => {
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setFullYear(endDate.getFullYear() + 2);
    endDate.setMonth(endDate.getMonth() + 1);

    const checkUpMonths = [];

    while (currentDate < endDate) {
        const monthText = currentDate.toLocaleDateString('default', { month: 'short', year: 'numeric' });
        const monthValue = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
        checkUpMonths.push({ text: monthText, value: monthValue });
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return checkUpMonths;
  };

  const handleToggleActive = async () => {
    const newStatus = pendingActiveStatus !== null ? !pendingActiveStatus : !isActive;

    if (newStatus) {
        try {
            const response = await axios.get('/filters/get/combined-count', { withCredentials: true });
            const { combinedCount } = response.data;

            const activeFiltersExcludingThis = isActive ? combinedCount - 1 : combinedCount;

            if (activeFiltersExcludingThis >= filterLimit) {
                setShowLimitPopup(true);
                return;
            }
        } catch (error) {
            console.error('Error checking filter limit:', error);
        }
    }
    
    setPendingActiveStatus(newStatus);
};


useEffect(() => {
    if (errorMessage) {
        setErrorMessage(errorMessage);
    }
}, [errorMessage]);


useEffect(() => {
    if (!filterId) return;

    const fetchFilter = async () => {
        setLoading(true); // Parāda ielādes stāvokli
        try {
            const response = await axios.get(`/filters/get/filters`);
            const filtersData = response.data[filterId];

            if (filtersData && filtersData.length > 0) {
                setFilterData(filtersData[0]);
                setIsActive(filtersData[0].active === 1);
            }
        } catch (error) {
            console.error("Error fetching filter data:", error);
        } finally {
            setLoading(false); // Noņem ielādes stāvokli pēc datu iegūšanas
        }
    };

    fetchFilter();
}, [filterId]);


  const openDropdown = (setDropdownState, dropdownRef, isOpen = true) => {
    setDropdownState(isOpen);
    setTimeout(() => {
        if (dropdownRef.current) {
            dropdownRef.current.style.zIndex = isOpen ? 9999 : ''; // Atiestata z-index, ja dropdown ir aizvērts
        }
    }, 0);
};

// Toggle dropdowns with immediate open
const toggleCarModelDropdown = () => {
    const remainingCarModels = Object.entries(carData.car_brand[filterData.carBrand].car_models)
      .filter(([modelValue, modelDisplay]) => modelDisplay !== "All" && !filterData.carModel.includes(modelValue));
    if (remainingCarModels.length > 0) {
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, true, remainingCarModels.length);
    } else {
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, false);
    }
  };
  
  const toggleEngineDropdown = () => {
    const remainingEngines = Object.entries(carData.engine_type)
      .filter(([engineValue, engineDisplay]) => engineDisplay !== "All" && !filterData.engine.includes(engineValue));
    if (remainingEngines.length > 0) {
      openDropdown(setShowEngineDropdown, engineDropdownRef, true, remainingEngines.length);
    } else {
      openDropdown(setShowEngineDropdown, engineDropdownRef, false);
    }
  };
  
  const toggleBodyTypeDropdown = () => {
    const remainingBodyTypes = Object.entries(carData.body_type)
      .filter(([bodyTypeValue, bodyTypeDisplay]) => bodyTypeDisplay !== "All" && !filterData.bodyType.includes(bodyTypeValue));
    if (remainingBodyTypes.length > 0) {
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, true, remainingBodyTypes.length);
    } else {
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false);
    }
  };
  
  const toggleColorDropdown = () => {
    const remainingColors = Object.entries(carData.color)
      .filter(([colorValue, colorDisplay]) => colorDisplay !== "All" && !filterData.color.includes(colorValue));
    if (remainingColors.length > 0) {
      openDropdown(setShowColorDropdown, colorDropdownRef, true, remainingColors.length);
    } else {
      openDropdown(setShowColorDropdown, colorDropdownRef, false);
    }
  };
  
  const toggleAreaDropdown = () => {
    const remainingAreas = Object.entries(carData.area)
      .filter(([areaValue, areaDisplay]) => areaDisplay !== "All" && !filterData.area.includes(areaValue));
    if (remainingAreas.length > 0) {
      openDropdown(setShowAreaDropdown, areaDropdownRef, true, remainingAreas.length);
    } else {
      openDropdown(setShowAreaDropdown, areaDropdownRef, false);
    }
  };

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (carModelDropdownRef.current && !carModelDropdownRef.current.contains(event.target)) {
              openDropdown(setShowCarModelDropdown, carModelDropdownRef, false);
          }
          if (engineDropdownRef.current && !engineDropdownRef.current.contains(event.target)) {
              openDropdown(setShowEngineDropdown, engineDropdownRef, false);
          }
          if (bodyTypeDropdownRef.current && !bodyTypeDropdownRef.current.contains(event.target)) {
              openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false);
          }
          if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
              openDropdown(setShowColorDropdown, colorDropdownRef, false);
          }
          if (areaDropdownRef.current && !areaDropdownRef.current.contains(event.target)) {
              openDropdown(setShowAreaDropdown, areaDropdownRef, false);
          }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  const handleSave = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
        const chosenStatus = pendingActiveStatus !== null ? pendingActiveStatus : isActive;
        const apiUrl = filterId ? `/filters/update/${filterId}` : '/filters/new';
        const requestMethod = filterId ? axios.put : axios.post;
        const selectedLanguage = localStorage.getItem('language') || 'lv';

        console.log("Sending request to:", apiUrl);
        console.log("Payload:", { filters: { ...filterData, active: chosenStatus } });
        console.log("Selected language:", selectedLanguage);

        // Pārbaudām, vai var aktivizēt jaunu filtru
        if (chosenStatus === 1) {
            try {
                const countResponse = await axios.get('/filters/get/combined-count', { withCredentials: true });
                const { combinedCount } = countResponse.data;

                if (combinedCount >= filterLimit) {
                    setShowLimitPopup(true); // Rāda paziņojumu, ja limits ir pārsniegts
                    setLoading(false);
                    return; // Pārtrauc saglabāšanu
                }
            } catch (error) {
                console.error('Error checking filter limit:', error);
            }
        }

        // Ja limits nav pārsniegts, saglabājam filtru
        const response = await requestMethod(apiUrl, { 
            filters: { ...filterData, active: chosenStatus } 
        }, { 
            headers: {
                'Content-Type': 'application/json',
                'language': selectedLanguage,
            },
            withCredentials: true 
        });

        if (response.status === 200) {
            if (!filterId) {
                const newFilterId = response.data.filterId;
                navigate(`/filters/edit/${newFilterId}`);
            } else {
                setIsActive(response.data.active);
            }

            setPendingActiveStatus(null);
            setSuccessMessage(text.saveSuccess);
            setTimeout(() => navigate('/filters'), 500);
        }
    } catch (error) {
        console.error('Error saving filter:', error);

        if (error.response?.data) {
            if (error.response.status === 400 && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage(text.saveError);
            }

            if (error.response.data.active !== undefined) {
                setIsActive(error.response.data.active);
            }
        } else {
            setErrorMessage(text.saveError);
        }
    } finally {
        setLoading(false);
    }
};


const handleDelete = async () => {
    setIsDeleting(true);
    try {
        await axios.delete(`/filters/delete/${filterId}`, { withCredentials: true });
        setTimeout(() => navigate('/filters'), 500);
    } catch (error) {
        console.error("Error deleting filter:", error);
    } finally {
        setIsDeleting(false);
        setShowConfirmDelete(false); // Aizver modāli pēc dzēšanas darbības
    }
};

  return (
    <div data-theme={theme}>
      <Container fluid id='filter-page-container'>
      {loading ? (
                <div className="loading-container">
                    <Spinner animation="border" /> {text.loading}
                </div>
            ) : (
                <>
        {/* Atpakaļ poga un filtrs */}
        <div className="header-container">
          <img
             src={backIcon}
             alt="Back"
             className="back-icon"
             onClick={() => navigate('/filters')}
          />
        <div className={`filter-status ${pendingActiveStatus !== null ? pendingActiveStatus : isActive ? 'active' : 'inactive'}`}>
    <span>
        {pendingActiveStatus !== null ? 
            (pendingActiveStatus ? text.enabled : text.disabled) : 
            (isActive ? text.enabled : text.disabled)
        }
    </span>
    <Form.Check
        type="switch"
        id={`filter-switch-${filterId}`}
        checked={pendingActiveStatus !== null ? pendingActiveStatus : isActive}
        onChange={handleToggleActive}
        className="filter-switch"
    />
</div>

<Modal show={showLimitPopup} onHide={() => setShowLimitPopup(false)} centered className="custom-modal">
    <Modal.Header>
        <Modal.Title>
        <h2 className="filter-name-title">{text.filterLimitTitle}</h2>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>{text.filterLimitMessage}</Modal.Body>
    <Modal.Footer>
        <Button variant="primary" className="btn-upgrade">
            {text.upgrade}
        </Button>
        <Button variant="primary" onClick={() => setShowLimitPopup(false)} className="btn-ok">
            {text.ok}
        </Button>
    </Modal.Footer>
</Modal>



        </div>
        {/* Filtra forma */}
        <Form className="filters-form">
         {/* Filter Name - Editable Title */}
<div className="filter-name-container" onClick={startEditing}>
    {isEditing ? (
        <input
            type="text"
            className="filter-name-input"
            value={filterData.filterName}
            onChange={handleFilterNameChange}
            onBlur={stopEditing}
            onKeyDown={(e) => e.key === "Enter" && stopEditing()} // Nospiežot Enter, saglabā
            autoFocus
            maxLength={15}
            onClick={(e) => e.stopPropagation()} // Neļauj klikšķim iziet ārpus input
        />
    ) : (
        <h2 className="filter-name-title">
            {filterData.filterName}
            <div className="underline"></div> {/* Apakšējā līnija */}
        </h2>
    )}
</div>

{/* Car Brand */}
<div className="row input-row">
    <div className="col-md-6 input-container">
        <label>{text.carBrandLabel}
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.carBrandTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
        </label>
        <div className="floating-input dropdown-wrapper">
            <Form.Select
                name="carBrand"
                value={filterData.carBrand === '0' ? '' : filterData.carBrand}
                onChange={handleChange}
                className="form-control custom-dropdown"
                required
            >
                <option value="" disabled hidden>
                    {text.carBrand}
                </option>
                {Object.entries(carData.car_brand).map(([key, { display, value }]) => (
                    <option key={key} value={value}>
                        {display}
                    </option>
                ))}
            </Form.Select>
            <img src={DropdownIcon} alt="Dropdown Icon" className="custom-dropdown-icon" />
        </div>
    </div>
</div>

{/* Car Model */}
{filterData.carBrand && filterData.carBrand !== "0" && (
    <div className="col-md-6 input-container">
        <label>{text.carModelLabel}
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.carModelTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
        </label>
        <div 
            className="car-model-field" 
            ref={carModelDropdownRef} 
            onClick={(e) => {
                e.stopPropagation(); 
                toggleCarModelDropdown();
            }}
        >
            {/* Placeholder and Selected Models */}
            <div className="selected-models-container">
                {filterData.carModel.length === 0 && !showCarModelDropdown ? (
                    <span className="placeholder-text">
                        {text.carModel} 
                    </span>
                ) : (
                    filterData.carModel.map((model, index) => (
                        <div
                            key={index}
                            className="selected-model"
                            onClick={() => handleRemoveCarModel(model)}
                        >
                            <span>
                                {
                                    carData.car_brand[filterData.carBrand].car_models[model]
                                }
                            </span>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveCarModel(model);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                )}
            </div>

            {/* Add Button */}
            <div className="add-button-container">
                <button
                    type="button"
                    className="add-button"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        toggleCarModelDropdown(); // Trigger dropdown opening when "+" is clicked
                    }}
                >
                    <img
                        src={pluss}
                        alt="Add Icon"
                        className="add-icon"
                    />
                </button>
            </div>

            {/* Dropdown List */}
            {showCarModelDropdown && (
                <div
                    className="dropdown-list above-elements"
                    ref={(el) => {
                        if (el) {
                            const containerWidth =
                                carModelDropdownRef.current.offsetWidth;
                            el.style.width = `${containerWidth}px`; 
                        }
                    }}
                >
                    {Object.entries(
                        carData.car_brand[filterData.carBrand].car_models
                    )
                        .filter(
                            ([modelValue, modelDisplay]) =>
                                modelDisplay !== "All" &&
                                !filterData.carModel.includes(modelValue)
                        )
                        .map(([modelValue, modelDisplay]) => (
                            <div
                                key={modelValue}
                                className="dropdown-item"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent bubbling up the event
                                    handleAddCarModel(modelValue); // Add model
                                    openDropdown(setShowCarModelDropdown, carModelDropdownRef, false); // Close dropdown after selection
                                }}
                            >
                                {modelDisplay}
                            </div>
                        ))}
                </div>
            )}
        </div>
    </div>
)}

{/* Price */}
<div className="input-container">
  <div className="input-group">
    {/* Kreisais lauks */}
    <div className="input-wrapper">
      <label htmlFor="cool-control-left">
        {text.priceFrom || "Cena no"}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.priceFromTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="cool-control-left"
        type="number"
        placeholder={text.priceF} 
        name="priceMin"
        value={filterData.priceMin || ''} 
        onChange={handleChange}
        min="0"
        className="form-control"
      />
    </div>

    {/* Simbols "-" precīzi pa vidu */}
    <div className="symbol-wrapper">
      <span className="input-group-text">-</span>
    </div>

    {/* Labais lauks */}
    <div className="input-wrapper">
      <label htmlFor="cool-control-right">
        {text.priceTo || "Cena līdz"}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.priceToTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="cool-control-right"
        type="number"
        placeholder={text.priceT} 
        name="priceMax"
        value={filterData.priceMax || ''} 
        onChange={handleChange}
        min="0"
        className="form-control"
      />
    </div>
  </div>
</div>

         {/* Year */}
<div className="input-container">
  <div className="input-group">
    {/* Kreisais lauks */}
    <div className="input-wrapper">
      <label htmlFor="year-control-left">{text.yearFrom || "Gads no"}
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.yearFromTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="year-control-left"
        type="number"
        placeholder={text.yearF} 
        name="yearMin"
        value={filterData.yearMin === '-' ? '' : filterData.yearMin}
        onChange={handleChange}
        min="1900" 
        max={new Date().getFullYear()} 
        className="form-control"
      />
    </div>

    {/* Simbols "-" precīzi pa vidu */}
    <div className="symbol-wrapper">
      <span className="input-group-text">-</span>
    </div>

    {/* Labais lauks */}
    <div className="input-wrapper">
      <label htmlFor="year-control-right">{text.yearTo || "Gads līdz"}
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.yearToTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="year-control-right"
        type="number"
        placeholder={text.yearT} 
        name="yearMax"
        value={filterData.yearMax === '-' ? '' : filterData.yearMax} 
        onChange={handleChange}
        min="1900" 
        max={new Date().getFullYear()} 
        className="form-control"
      />
    </div>
  </div>
</div>


          <div className="row input-row">
    {/* Color */}
<div className="col-md-6 input-container">
    <label>{text.colorLabel}
    <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.colorTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
    </label>
    <div 
        className="car-model-field" 
        ref={colorDropdownRef} 
        onClick={(e) => {
            e.stopPropagation(); 
            toggleColorDropdown();
        }}
    >
        {/* Placeholder and Selected Colors */}
        <div className="selected-models-container">
            {filterData.color.length === 0 && !showColorDropdown ? (
                <span className="placeholder-text">
                    {text.color} 
                </span>
            ) : (
                filterData.color.map((color, index) => (
                    <div
                        key={index}
                        className="selected-model"
                        onClick={() => handleRemoveColor(color)}
                    >
                        <span>{carData.color[color]}</span>
                        <button
                            type="button"
                            className="remove-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveColor(color);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                ))
            )}
        </div>

        {/* Add Button */}
        <div className="add-button-container">
            <button
                type="button"
                className="add-button"
                onClick={(e) => {
                    e.stopPropagation(); 
                    toggleColorDropdown();
                }}
            >
                <img
                    src={pluss}
                    alt="Add Icon"
                    className="add-icon"
                />
            </button>
        </div>

        {/* Dropdown List */}
        {showColorDropdown && (
            <div
                className="dropdown-list above-elements"
                ref={(el) => {
                    if (el) {
                        const containerWidth =
                            colorDropdownRef.current.offsetWidth;
                        el.style.width = `${containerWidth}px`; 
                    }
                }}
            >
                {Object.entries(carData.color)
                    .filter(
                        ([colorValue, colorDisplay]) =>
                            colorDisplay !== "All" &&
                            !filterData.color.includes(colorValue)
                    )
                    .map(([colorValue, colorDisplay]) => (
                        <div
                            key={colorValue}
                            className="dropdown-item"
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleAddColor(colorValue); 
                                openDropdown(setShowColorDropdown, colorDropdownRef, false);
                            }}
                        >
                            {colorDisplay}
                        </div>
                    ))}
            </div>
        )}
    </div>
</div>

{/* Area */}
<div className="col-md-6 input-container">
    <label>{text.areaLabel}
    <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.areaTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
    </label>
    <div 
        className="car-model-field" 
        ref={areaDropdownRef} 
        onClick={(e) => {
            e.stopPropagation(); 
            toggleAreaDropdown(); 
        }}
    >
        <div className="selected-models-container">
            {filterData.area.length === 0 && !showAreaDropdown ? (
                <span className="placeholder-text">
                    {text.area}
                </span>
            ) : (
                filterData.area.map((area, index) => (
                    <div
                        key={index}
                        className="selected-model"
                        onClick={() => handleRemoveArea(area)}
                    >
                        <span>{carData.area[area]}</span>
                        <button
                            type="button"
                            className="remove-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveArea(area);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                ))
            )}
        </div>

        {/* Add Button */}
        <div className="add-button-container">
            <button
                type="button"
                className="add-button"
                onClick={(e) => {
                    e.stopPropagation(); 
                    toggleAreaDropdown();
                }}
            >
                <img
                    src={pluss}
                    alt="Add Icon"
                    className="add-icon"
                />
            </button>
        </div>

        {/* Dropdown List */}
        {showAreaDropdown && (
            <div
                className="dropdown-list above-elements"
                ref={(el) => {
                    if (el) {
                        const containerWidth =
                            areaDropdownRef.current.offsetWidth;
                        el.style.width = `${containerWidth}px`; 
                    }
                }}
            >
                {Object.entries(carData.area)
                    .filter(
                        ([areaValue, areaDisplay]) =>
                            areaDisplay !== "All" &&
                            !filterData.area.includes(areaValue)
                    )
                    .map(([areaValue, areaDisplay]) => (
                        <div
                            key={areaValue}
                            className="dropdown-item"
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleAddArea(areaValue);
                                openDropdown(setShowAreaDropdown, areaDropdownRef, false); 
                            }}
                        >
                            {areaDisplay}
                        </div>
                    ))}
            </div>
        )}
    </div>
</div>

</div>


          {/* Detalizētie filtri */}
          <div className="details-section">
            <div className="details-toggle d-flex align-items-center">
              <span
                className="me-2"
                onClick={toggleDetails}
              >
                {text.details}
              </span>
              <img
                src={isDetailsExpanded ? arrowDownIcon : arrowRightIcon}
                alt="Toggle Details"
                className="details-toggle-icon"
                onClick={toggleDetails}
              />
            </div>

            {isDetailsExpanded && (
        <div className="details-content">

<div className="row input-row">
{/* Engine Type */}
<div className="col-md-6 input-container">
<label>
        {text.engineTypeLabel}
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.engineTypeTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
    </label>
    
    <div 
        className="car-model-field" 
        ref={engineDropdownRef} 
        onClick={(e) => {
            e.stopPropagation(); 
            toggleEngineDropdown(); 
        }}
    >
        <div className="selected-models-container">
            {filterData.engine.length === 0 && !showEngineDropdown ? (
                <span className="placeholder-text">{text.engineType}</span>
            ) : (
                filterData.engine.map((engine, index) => (
                    <div
                        key={index}
                        className="selected-model"
                        onClick={() => handleRemoveEngine(engine)}
                    >
                        <span>{carData.engine_type[engine]}</span>
                        <button
                            type="button"
                            className="remove-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveEngine(engine);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                ))
            )}
        </div>

        {/* Add Button */}
        <div className="add-button-container">
            <button
                type="button"
                className="add-button"
                onClick={(e) => {
                    e.stopPropagation(); 
                    toggleEngineDropdown();
                }}
            >
                <img
                    src={pluss}
                    alt="Add Icon"
                    className="add-icon"
                />
            </button>
        </div>

        {/* Dropdown List */}
        {showEngineDropdown && (
            <div
                className="dropdown-list above-elements"
                ref={(el) => {
                    if (el) {
                        const containerWidth =
                            engineDropdownRef.current.offsetWidth;
                        el.style.width = `${containerWidth}px`; 
                    }
                }}
            >
                {Object.entries(carData.engine_type)
                    .filter(
                        ([engineValue, engineDisplay]) =>
                            engineDisplay !== "All" &&
                            !filterData.engine.includes(engineValue)
                    )
                    .map(([engineValue, engineDisplay]) => (
                        <div
                            key={engineValue}
                            className="dropdown-item"
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleAddEngine(engineValue); 
                                openDropdown(setShowEngineDropdown, engineDropdownRef, false); 
                            }}
                        >
                            {engineDisplay}
                        </div>
                    ))}
            </div>
        )}
    </div>
</div>

{/* Body Type */}
<div className="col-md-6 input-container">
    <label>{text.bodyTypeLabel}
    <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.bodyTypeTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
    </label>
    <div 
        className="car-model-field" 
        ref={bodyTypeDropdownRef}
        onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            toggleBodyTypeDropdown(); // Open/Close dropdown on click (both for input and button)
        }}
    >
        {/* Placeholder and Selected Body Types */}
        <div className="selected-models-container">
            {filterData.bodyType.length === 0 && !showBodyTypeDropdown ? (
                <span className="placeholder-text">{text.bodyType}</span>
            ) : (
                filterData.bodyType.map((bodyType, index) => (
                    <div
                        key={index}
                        className="selected-model"
                        onClick={() => handleRemoveBodyType(bodyType)}
                    >
                        <span>{carData.body_type[bodyType]}</span>
                        <button
                            type="button"
                            className="remove-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveBodyType(bodyType);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                ))
            )}
        </div>

        {/* Add Button */}
        <div className="add-button-container">
            <button
                type="button"
                className="add-button"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation
                    toggleBodyTypeDropdown(); // Trigger dropdown opening when "+" is clicked
                }}
            >
                <img
                    src={pluss}
                    alt="Add Icon"
                    className="add-icon"
                />
            </button>
        </div>

        {/* Dropdown List */}
        {showBodyTypeDropdown && (
            <div
                className="dropdown-list above-elements"
                ref={(el) => {
                    if (el) {
                        const containerWidth =
                            bodyTypeDropdownRef.current.offsetWidth;
                        el.style.width = `${containerWidth}px`; // Match width with parent
                    }
                }}
            >
                {Object.entries(carData.body_type)
                    .filter(
                        ([bodyTypeValue, bodyTypeDisplay]) =>
                            bodyTypeDisplay !== "All" &&
                            !filterData.bodyType.includes(bodyTypeValue)
                    )
                    .map(([bodyTypeValue, bodyTypeDisplay]) => (
                        <div
                            key={bodyTypeValue}
                            className="dropdown-item"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent bubbling up the event
                                handleAddBodyType(bodyTypeValue); // Add body type
                                openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false); // Close dropdown after selection
                            }}
                        >
                            {bodyTypeDisplay}
                        </div>
                    ))}
            </div>
        )}
    </div>
</div>

</div>

               {/* Volume */}
{showVolume && (
    <div className="input-container">
        <div className="input-group">
            {/* Kreisais lauks */}
            <div className="input-wrapper">
                <label htmlFor="volume-control-left">{text.volumeFrom || "Tilpums no"}
                <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.volumeFromTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
                </label>
                <input
                    id="volume-control-left"
                    type="number"
                    step=".1"
                    placeholder={text.volumeF} 
                    name="volumeMin"
                    value={filterData.volumeMin || ''} 
                    onChange={handleChange}
                    min="0" 
                    className="form-control"
                />
            </div>

            {/* Simbols "-" precīzi pa vidu */}
            <div className="symbol-wrapper">
                <span className="input-group-text">-</span>
            </div>

            {/* Labais lauks */}
            <div className="input-wrapper">
                <label htmlFor="volume-control-right">{text.volumeTo || "Tilpums līdz"}
                <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.volumeToTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
                </label>
                <input
                    id="volume-control-right"
                    type="number"
                    step=".1"
                    placeholder={text.volumeT} 
                    name="volumeMax"
                    value={filterData.volumeMax || ''} 
                    onChange={handleChange}
                    min="0" 
                    className="form-control"
                />
            </div>
        </div>
    </div>
)}

              {/* Gearbox */}
<div className="row input-row">
    <div className="col-md-6 input-container">
        <label>{text.gearboxLabel}
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.gearboxTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
        </label>
        <div className="floating-input dropdown-wrapper">
            <Form.Select
                name="gearBox"
                value={filterData.gearBox === '0' ? '' : filterData.gearBox}
                onChange={handleChange}
                className="form-control custom-dropdown"
                required
            >
                <option value="" disabled hidden>
                    {text.gearbox}
                </option>
                {Object.entries(carData.gearbox).map(([value, displayText]) => (
                    <option key={value} value={value}>
                        {displayText}
                    </option>
                ))}
            </Form.Select>
            <img src={DropdownIcon} alt="Dropdown Icon" className="custom-dropdown-icon" />
        </div>
    </div>

               {/* Run Distance */}
<div className="input-container">
  <div className="input-group">
    {/* Kreisais lauks */}
    <div className="input-wrapper">
      <label htmlFor="run-control-left">{text.runDistanceFrom || "Nobraukums no"}
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.runDistanceFromTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="run-control-left"
        type="number"
        placeholder={text.runDistanceF} 
        name="runMin"
        value={filterData.runMin || ''} 
        onChange={handleChange}
        min="0" 
        className="form-control"
      />
    </div>

    {/* Simbols "-" precīzi pa vidu */}
    <div className="symbol-wrapper">
      <span className="input-group-text">-</span>
    </div>

    {/* Labais lauks */}
    <div className="input-wrapper">
      <label htmlFor="run-control-right">{text.runDistanceTo || "Nobraukums līdz"}
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{text.runDistanceToTooltip}</Tooltip>}
        >
          <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
      </label>
      <input
        id="run-control-right"
        type="number"
        placeholder={text.runDistanceT} 
        name="runMax"
        value={filterData.runMax || ''} 
        onChange={handleChange}
        min="0" 
        className="form-control"
      />
    </div>
  </div>
</div>

                {/* Deal Type */}
<div className="input-container">
    <label>{text.dealTypeLabel}
    <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.dealTypeTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
    </label>
    <div className="floating-input dropdown-wrapper">
        <Form.Select
            name="dealType"
            value={filterData.dealType === '0' ? '' : filterData.dealType}
            onChange={handleChange}
            className="form-control custom-dropdown"
            required
        >
            <option value="" disabled hidden>
                {text.dealType}
            </option>
            {Object.entries(carData.deal_type).map(([value, text]) => (
                <option key={value} value={value}>
                    {text}
                </option>
            ))}
        </Form.Select>
        <img src={DropdownIcon} alt="Dropdown Icon" className="custom-dropdown-icon" />
    </div>
</div>

        {/* Checkup */}
        <div className="col-md-6 input-container">
            <label>{text.checkupLabel}
            <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{text.checkupTooltip}</Tooltip>}
        >
            <InfoCircle className="ms-2 info-icon" />
        </OverlayTrigger>
            </label>
            <div className="floating-input dropdown-wrapper">
                <Form.Select
                    name="checkup"
                    value={filterData.checkup ?? ''} 
                    onChange={(e) => {
                        const value = e.target.value === 'any' ? 'any' : e.target.value;
                        handleChange({ target: { name: e.target.name, value } });
                    }}
                    className="form-control custom-dropdown"
                    required
                >
                    {/* Placeholder */}
                    <option value="" disabled hidden>
                        {text.checkup} 
                    </option>
                    {/* Opcija "Any" */}
                    <option value="any">
                        {text.checkupAnyLabel}
                    </option>
                    {/* Ģenerētās opcijas */}
                    {generateCheckUpMonths().map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.text}
                        </option>
                    ))}
                </Form.Select>
                <img src={DropdownIcon} alt="Dropdown Icon" className="custom-dropdown-icon" />
            </div>
        </div>
                </div>
              </div>
            )}
          </div>
          
          {errorMessage && (
    <Alert variant="danger" className="mt-3">
        {errorMessage}
    </Alert>
)}

          <Row className="button-row">
    <Col xs={filterId ? 8 : 12} sm={filterId ? 9 : 12} md={filterId ? 10 : 12} xxl={filterId ? 11 : 12} className="mt-3">
        <Button 
            variant="primary" 
            className="btn-save d-flex align-items-center justify-content-center"
            onClick={handleSave} 
            disabled={loading}
        >
            {loading ? (
                <>
                    <Spinner animation="border" size="sm" className="mr-2" />
                    {text.savingText}
                </>
            ) : (
                <>
                    {text.saveButton}
                    <img src={saveIcon} alt="Save Icon" className="btn-icon" />
                </>
            )}
        </Button>
    </Col>

    {filterId && (
        <Col xs="auto" className="mt-3 d-flex align-items-center justify-content-center">
            <Button
                variant="danger"
                className="btn-delete"
                onClick={() => setShowConfirmDelete(true)} 
                disabled={isDeleting}
            >
                {isDeleting ? (
                    <Spinner animation="border" size="sm" />
                ) : (
                    <img src={trashIcon} alt="Trash Icon" className="btn-icon" />
                )}
            </Button>
        </Col>
    )}

<Modal
  show={showConfirmDelete}
  onHide={() => setShowConfirmDelete(false)}
  centered
  className="custom-modal"
>
  <Modal.Header>
    <Modal.Title>
      <div className="filter-name-container">
        {text.confirmDeleteTitle}
        <h2 className="filter-name-title">
          {filterData.filterName}
        </h2>
      </div>
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <p dangerouslySetInnerHTML={{ __html: text.confirmDeleteMessage }} />
</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
      {text.cancel}
    </Button>
    <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
      {isDeleting ? <Spinner animation="border" size="sm" /> : text.confirm}
    </Button>
  </Modal.Footer>
</Modal>


</Row>
{/* ---------------------- */}
        </Form>
        </>
        )}
      </Container>
    </div>
  );
}

export default FiltersFormPage;