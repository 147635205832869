import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTheme } from '../../ThemeContext.js';
import '../../styles/navbar.scss';
import logo from '../../img/sw_logo-white.svg';
import burgerIcon from '../../img/burger_menu-white.svg';
import translations from '../../translate.js';
import { fetchWithLanguage } from '../../utils/fetchWithLanguage.js';

// Import icons
// import RegisterIcon from '../../img/Register.svg';
// import LoginIcon from '../../img/Login.svg';
import ModeIcon from '../../img/mode.svg';
import LanguageIcon from '../../img/language.svg';
import arrowRightIcon from '../../img/arrow-right.svg';
import arrowDownIcon from '../../img/arrow-down.svg';
// import profileIcon from '../../img/profile.svg';

function NavigationBar() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'lv');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const dropdownContainerRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 1199px)' });
// Determine if app is running inside Telegram Mini App
const isMiniApp = Boolean(
  typeof window !== "undefined" &&
  window.Telegram?.WebApp &&
  (navigator.userAgent.includes("Telegram") || Object.keys(window.Telegram.WebApp.initDataUnsafe).length > 0)
);

  const { theme, toggleTheme } = useTheme();

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    setIsLanguageDropdownOpen(false); 
    setIsDropdownOpen(false); 
    window.location.reload();
  };

  const handleThemeChange = (mode) => {
    if (mode !== theme) {
      toggleTheme();
    }
    setIsThemeDropdownOpen(false); 
    setIsDropdownOpen(false); 
  };

  const toggleThemeDropdown = () => {
    setIsThemeDropdownOpen((prev) => !prev);
    adjustDropdownHeight();
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen((prev) => !prev);
    adjustDropdownHeight();
  };

  const toggleMainDropdown = () => {
    setIsDropdownOpen((prev) => !prev); 
  };

  const adjustDropdownHeight = () => {
    if (dropdownContainerRef.current) {
      const container = dropdownContainerRef.current;
      const contentHeight = container.scrollHeight;
      container.style.height = `${contentHeight}px`;
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownContainerRef.current &&
      !dropdownContainerRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false); 
      setIsThemeDropdownOpen(false);
      setIsLanguageDropdownOpen(false); 
    }
  };

  const handleSignOut = async () => {
    if (isMiniApp) {
      console.log("Sign out is disabled in Telegram Mini App");
      return; // Don't log out if inside Telegram
    }
  
    try {
      const response = await fetch('/api/auth/signout', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (response.ok) {
        window.location.href = '/login';
      } else {
        console.error('Failed to log out:', response.statusText);
      }
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };
  
  // useEffect(() => {
  //   console.log("User Agent:", navigator.userAgent);
  //   console.log("Telegram WebApp Object:", window.Telegram?.WebApp);
  //   console.log("Telegram initDataUnsafe:", window.Telegram?.WebApp?.initDataUnsafe);
  //   console.log("Corrected isMiniApp:", isMiniApp);
  // }, [isMiniApp]);
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      setIsLoading(true);
      try {
        const data = await fetchWithLanguage('/api/auth/status', language);
        setIsLoggedIn(data.isLoggedIn);
      } catch (error) {
        console.error('Error fetching authentication status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuthStatus();
  }, [language]);

  const GuestLinks = () => (
    <NavDropdown
      align="end"
      title={<span onClick={toggleMainDropdown}>{translations[language].profile}</span>}
      id="guest-links-dropdown"
      className="custom-dropdown"
      show={isDropdownOpen}
    >
      <div ref={dropdownContainerRef} className="dropdown-container">
      {/* {isMiniApp && (
        <NavDropdown.Item as={Link} to="/signup" className="dropdown-item-with-icon">
          <img src={RegisterIcon} alt="Register" className="icon" />
          {translations[language].signUp}
        </NavDropdown.Item>
      )} */}

        {/* <NavDropdown.Item as={Link} to="/login" className="dropdown-item-with-icon">
          <img src={LoginIcon} alt="Login" className="icon" />
          {translations[language].login}
        </NavDropdown.Item> */}

                              {/* Theme Dropdown */}
                      <NavDropdown.Item
                        className="dropdown-item-with-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleThemeDropdown();
                        }}
                        onMouseEnter={(e) => {
                          const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                          if (arrow && !isThemeDropdownOpen) {
                            arrow.classList.add('hover-visible');
                          }
                        }}
                        onMouseLeave={(e) => {
                          const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                          if (arrow && !isThemeDropdownOpen) {
                            arrow.classList.remove('hover-visible');
                          }
                        }}
                      >
                        <img src={ModeIcon} alt="Mode" className="icon" />
                        {translations[language].themeMode}
                        <img
                          src={isThemeDropdownOpen ? arrowDownIcon : arrowRightIcon}
                          alt="Dropdown Arrow"
                          className={`dropdown-arrow ${isThemeDropdownOpen ? 'open' : 'closed'} ${
                            isThemeDropdownOpen ? 'visible' : ''
                          }`}
                        />
                      </NavDropdown.Item>
                      {isThemeDropdownOpen && (
                        <div className="nested-dropdown">
                          <NavDropdown.Item
                            className={`dropdown-option ${theme === 'light' ? 'active-option' : ''}`}
                            onClick={() => handleThemeChange('light')}
                          >
                            {translations[language].lightMode}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            className={`dropdown-option ${theme === 'dark' ? 'active-option' : ''}`}
                            onClick={() => handleThemeChange('dark')}
                          >
                            {translations[language].darkMode}
                          </NavDropdown.Item>
                        </div>
                      )}

                  {/* Language Dropdown */}
                  <NavDropdown.Item
            className="dropdown-item-with-icon"
            onClick={(e) => {
              e.preventDefault();
              toggleLanguageDropdown();
            }}
            onMouseEnter={(e) => {
              const arrow = e.currentTarget.querySelector('.dropdown-arrow');
              if (arrow && !isLanguageDropdownOpen) {
                arrow.classList.add('hover-visible');
              }
            }}
            onMouseLeave={(e) => {
              const arrow = e.currentTarget.querySelector('.dropdown-arrow');
              if (arrow && !isLanguageDropdownOpen) {
                arrow.classList.remove('hover-visible');
              }
            }}
          >
            <img src={LanguageIcon} alt="Language" className="icon" />
            {translations[language].language}
            <img
              src={isLanguageDropdownOpen ? arrowDownIcon : arrowRightIcon}
              alt="Dropdown Arrow"
              className={`dropdown-arrow ${isLanguageDropdownOpen ? 'open' : 'closed'} ${
                isLanguageDropdownOpen ? 'visible' : ''
              }`}
            />
          </NavDropdown.Item>
          {isLanguageDropdownOpen && (
            <div className="nested-dropdown">
              <NavDropdown.Item
                className={`dropdown-option ${language === 'lv' ? 'active-option' : ''}`}
                onClick={() => handleLanguageChange('lv')}
              >
                LV
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`dropdown-option ${language === 'en' ? 'active-option' : ''}`}
                onClick={() => handleLanguageChange('en')}
              >
                EN
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`dropdown-option ${language === 'ru' ? 'active-option' : ''}`}
                onClick={() => handleLanguageChange('ru')}
              >
                RU
              </NavDropdown.Item>
            </div>
          )}
      </div>
    </NavDropdown>
  );

  const AuthLinks = () => (
    <NavDropdown
      align="end"
      title={<span onClick={() => setIsDropdownOpen(!isDropdownOpen)}>{translations[language].profile}</span>}
      id="auth-links-dropdown"
      className="custom-dropdown"
      show={isDropdownOpen} 
    >
      <div ref={dropdownContainerRef} className="dropdown-container">
        {/* <NavDropdown.Item as={Link} to="/profile" className="dropdown-item-with-icon">
          <img src={profileIcon} alt="Profile" className="icon" />
          {translations[language].profile}
        </NavDropdown.Item> */}
  
                           {/* Theme Dropdown */}
                <NavDropdown.Item
                  className="dropdown-item-with-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleThemeDropdown();
                  }}
                  onMouseEnter={(e) => {
                    const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                    if (arrow && !isThemeDropdownOpen) {
                      arrow.classList.add('hover-visible');
                    }
                  }}
                  onMouseLeave={(e) => {
                    const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                    if (arrow && !isThemeDropdownOpen) {
                      arrow.classList.remove('hover-visible');
                    }
                  }}
                >
                  <img src={ModeIcon} alt="Mode" className="icon" />
                  {translations[language].themeMode}
                  <img
                    src={isThemeDropdownOpen ? arrowDownIcon : arrowRightIcon}
                    alt="Dropdown Arrow"
                    className={`dropdown-arrow ${isThemeDropdownOpen ? 'open' : 'closed'} ${
                      isThemeDropdownOpen ? 'visible' : ''
                    }`}
                  />
                </NavDropdown.Item>
                {isThemeDropdownOpen && (
                  <div className="nested-dropdown">
                    <NavDropdown.Item
                      className={`dropdown-option ${theme === 'light' ? 'active-option' : ''}`}
                      onClick={() => handleThemeChange('light')}
                    >
                      {translations[language].lightMode}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={`dropdown-option ${theme === 'dark' ? 'active-option' : ''}`}
                      onClick={() => handleThemeChange('dark')}
                    >
                      {translations[language].darkMode}
                    </NavDropdown.Item>
                  </div>
                )}

                        {/* Language Dropdown */}
                  <NavDropdown.Item
                    className="dropdown-item-with-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleLanguageDropdown();
                    }}
                    onMouseEnter={(e) => {
                      const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                      if (arrow && !isLanguageDropdownOpen) {
                        arrow.classList.add('hover-visible');
                      }
                    }}
                    onMouseLeave={(e) => {
                      const arrow = e.currentTarget.querySelector('.dropdown-arrow');
                      if (arrow && !isLanguageDropdownOpen) {
                        arrow.classList.remove('hover-visible');
                      }
                    }}
                  >
                    <img src={LanguageIcon} alt="Language" className="icon" />
                    {translations[language].language}
                    <img
                      src={isLanguageDropdownOpen ? arrowDownIcon : arrowRightIcon}
                      alt="Dropdown Arrow"
                      className={`dropdown-arrow ${isLanguageDropdownOpen ? 'open' : 'closed'} ${
                        isLanguageDropdownOpen ? 'visible' : ''
                      }`}
                    />
                  </NavDropdown.Item>
                  {isLanguageDropdownOpen && (
                    <div className="nested-dropdown">
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'lv' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('lv')}
                      >
                        LV
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'en' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('en')}
                      >
                        EN
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'ru' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('ru')}
                      >
                        RU
                      </NavDropdown.Item>
                    </div>
                  )}
                        </div>
    </NavDropdown>
  );
  
  return (
       <div data-theme={theme}>
      <Navbar expand="xl" className="custom-navbar">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/" className="nav-link" aria-label="Homepage">
              <img src={logo} alt="StalkerWind" className="nav-logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-xl-none">
            <img src={burgerIcon} alt="Menu" style={{ width: '24px', height: '24px' }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            {/* {isMiniApp && (
              <Link to="/filters" className="nav-link text-center">
                {translations[language].products}
              </Link>
            )} */}
              {/* <Link to="/feedback" className="nav-link text-center">
                {translations[language].feedback}
              </Link> */}
            </Nav>
            <Nav className="d-flex flex-column align-items-center">
              {isLoading ? (
                <span className="nav-link text-center">{translations[language].loading}</span>
              ) : isMobile ? (
                <>
                  {isLoggedIn ? (
                    <>
                      {/* <Link to="/profile" className="nav-link text-center">
                        {translations[language].profile}
                      </Link> */}
                      <span className="horizontal-divider" />
                      {isLoggedIn && !isMiniApp && (
                      <Link onClick={handleSignOut} className="nav-link text-center">
                        {translations[language].signOut}
                      </Link>
                    )}
                    </>
                  ) : (
                    <>
                      {/* <Link to="/login" className="nav-link text-center">
                        {translations[language].login}
                      </Link>
                      <span className="horizontal-divider" />
                      <Link to="/signup" className="nav-link text-center">
                        {translations[language].signUp}
                      </Link> */}
                    </>
                  )}
                  <span className="horizontal-divider" />
                  <div className="dropdown-item-container">
                    {/* Theme Dropdown */}
                    <NavDropdown title={`${translations[language].themeMode} (${theme === 'light' ? translations[language].lightMode : translations[language].darkMode})`} id="theme-dropdown">
                      <NavDropdown.Item
                        className={`dropdown-option ${theme === 'light' ? 'active-option' : ''}`}
                        onClick={() => handleThemeChange('light')}
                      >
                        {translations[language].lightMode}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`dropdown-option ${theme === 'dark' ? 'active-option' : ''}`}
                        onClick={() => handleThemeChange('dark')}
                      >
                        {translations[language].darkMode}
                      </NavDropdown.Item>
                    </NavDropdown>
  
                    {/* <span className="horizontal-divider" /> */}
                    {/* Language Dropdown */}
                    <NavDropdown title={`${translations[language].language} (${language.toUpperCase()})`} id="language-dropdown">
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'lv' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('lv')}
                      >
                        Latvian
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'en' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('en')}
                      >
                        English
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`dropdown-option ${language === 'ru' ? 'active-option' : ''}`}
                        onClick={() => handleLanguageChange('ru')}
                      >
                        Russian
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </>
              ) : isLoggedIn ? (
                <AuthLinks />
              ) : (
                <GuestLinks />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
  );
  
}  

export default NavigationBar;