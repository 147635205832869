import React, { useEffect, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar.js';
import '../../styles/home.scss';
import { useTheme } from '../../ThemeContext.js';
import translations from '../../translate.js';

function HomePage() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const { theme } = useTheme();
    const navigate = useNavigate();

    // Check if the app is running inside Telegram Mini App
    const isMiniApp = Boolean(
        typeof window !== "undefined" &&
        window.Telegram?.WebApp &&
        (navigator.userAgent.includes("Telegram") || Object.keys(window.Telegram.WebApp.initDataUnsafe || {}).length > 0)
    );

    useEffect(() => {
        const fetchAuthStatus = async () => {
            try {
                const res = await fetch('/api/auth/status');
                const data = await res.json();
                setIsLoggedIn(data.isLoggedIn);
                // If inside Mini App and user is not logged in, redirect to signup
                if (isMiniApp && !data.isLoggedIn) {
                    navigate("/signup");
                }
            } catch (error) {
                console.error("Error fetching authentication status:", error);
            }
        };

        fetchAuthStatus();
    }, [isMiniApp, navigate]);

    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language]; 

    // const handleTryNowClick = () => {
    //     if (isMiniApp) {
    //         window.location.href = "/filters";
    //     } else if (isLoggedIn) {
    //         window.location.href = `https://t.me/teststalkerwind_bot?start=login`;
    //     } else {
    //         window.location.href = "https://t.me/teststalkerwind_bot";
    //     }
    // };

    const handleTryNowClick = () => {
        if (isMiniApp) {
            window.location.href = "/filters";
        } else {
            window.location.href = `https://t.me/stalkerwindbot?start=login`;
        }
    };

    return (
        <div data-theme={theme}>
            <div>
                <NavigationBar />
                <div id="home-div">
                    <div className="main-page">
                        <Col xs={12} className="left-column">
                            <div className="text-container" id="main-text1">
                                <p>{text.saveTime}</p>
                            </div>
                            <div className="text-container" id="main-text2">
                                <p>{text.receiveDeals}</p>
                            </div>
                            <div className="text-container" id="main-text3">
                                <p>{text.customizeFilters}</p>
                            </div>
                            <div className="button-container">
                                <Button
                                    variant="outline-light"
                                    id="main-button"
                                    onClick={handleTryNowClick}
                                >
                                    {text.tryNow}
                                    <span className="custom-icon">
                                        <svg
                                            className="button-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            <line x1="7" y1="17" x2="17" y2="7" stroke="currentColor" strokeWidth="2" />
                                            <polyline points="7 7 17 7 17 17" fill="none" stroke="currentColor" strokeWidth="2" />
                                        </svg>
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
