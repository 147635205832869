import React, { useState } from 'react';
import '../../styles/mainpage.scss';
import { useNavigate } from 'react-router-dom';
import lvFlag from '../../img/Latvia.png';
import enFlag from '../../img/UnitedKingdom.png';
import ruFlag from '../../img/Russia.png';
import filtersIcon from '../../img/FiltersIcon.svg';
import howItWorksIcon from '../../img/HowItWorks.svg';
import settingsIcon from '../../img/SettingsIcon.svg';
import contactIcon from '../../img/ContactUs.svg';
import reportIcon from '../../img/ReportProblem.svg';
import { useTheme } from '../../ThemeContext.js';
import translations from '../../translate.js';

const MainPage = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');
    const { theme } = useTheme();
    const navigate = useNavigate();

    const language = selectedLanguage || 'lv'; 
    const text = translations[language]; 

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('language', language);
    };

    return (
        <div data-theme={theme}>
            <div className="main-page-container">
                {/* Valodu izvēlne */}
                <div className="language-selector">
                    <div 
                        className={`language-option ${selectedLanguage === 'lv' ? 'active' : ''}`} 
                        onClick={() => handleLanguageSelect('lv')}
                    >
                        <img src={lvFlag} alt={text.latvia} />
                        <span>Latviešu</span>
                    </div>
                    <div 
                        className={`language-option ${selectedLanguage === 'en' ? 'active' : ''}`} 
                        onClick={() => handleLanguageSelect('en')}
                    >
                        <img src={enFlag} alt={text.english} />
                        <span>English</span>
                    </div>
                    <div 
                        className={`language-option ${selectedLanguage === 'ru' ? 'active' : ''}`} 
                        onClick={() => handleLanguageSelect('ru')}
                    >
                        <img src={ruFlag} alt={text.russian} />
                        <span>Русский</span>
                    </div>
                </div>

                  {/* Pogu sadaļa */}
                  <div className="button-section">
                  <button className="menu-button filters-button" onClick={() => navigate('/filters')}>
            <span>{text.filters}</span>
            <img src={filtersIcon} alt="Filters" />
        </button>
                    <button className="menu-button" disabled>
                        <span>{text.howItWorks}</span>
                        <img src={howItWorksIcon} alt="How it works" />
                    </button>
                    <button className="menu-button" disabled>
                        <span>{text.settings}</span>
                        <img src={settingsIcon} alt="Settings" />
                    </button>
                    <button className="menu-button" disabled>
                        <span>{text.contactUs}</span>
                        <img src={contactIcon} alt="Contact Us" />
                    </button>
                    <button className="menu-button report-button" disabled>
                        <span>{text.reportProblem}</span>
                        <img src={reportIcon} alt="Report a Problem" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
